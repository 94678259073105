import { faCheckCircle, faCircleExclamation, faCircleInfo, faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Icon, { ICONS, IconSize } from '@components/Icon/Icon';
import { NotificationStatus } from '../../common/utils/notify';

import './Notification.scss';

interface NotificationProps {
  title?: string;
  message: string;
  status: NotificationStatus;
  onClose: (() => void) | undefined;
  className?: string;
}

const Notification: React.FC<NotificationProps> = ({ title, message, status, className, onClose }): React.ReactElement => {
  const [isExpand, setIsExpand] = useState(true);

  const notificationStatus = {
    [NotificationStatus.SUCCESS]: {
      className: 'notification--success',
      icon: <FontAwesomeIcon icon={faCheckCircle} size="1x" color="#065f46" />,
      closeIcon: <FontAwesomeIcon className="notification__close-icon" icon={faXmark} size="1x" color="#065f46" onClick={onClose} />,
    },
    [NotificationStatus.INFO]: {
      className: 'notification--info',
      icon: <FontAwesomeIcon icon={faCircleInfo} size="1x" color="#1e40af" />,
      closeIcon: <FontAwesomeIcon className="notification__close-icon" icon={faXmark} size="1x" color="#1e40af" onClick={onClose} />,
    },
    [NotificationStatus.WARNING]: {
      className: 'notification--warning',
      icon: <FontAwesomeIcon icon={faTriangleExclamation} size="1x" color="#92400e" />,
      closeIcon: <FontAwesomeIcon className="notification__close-icon" icon={faXmark} size="1x" color="#92400e" onClick={onClose} />,
    },
    [NotificationStatus.ERROR]: {
      className: 'notification--error',
      icon: <FontAwesomeIcon icon={faCircleExclamation} size="1x" color="#b91c1c" />,
      closeIcon: <FontAwesomeIcon className="notification__close-icon" icon={faXmark} size="1x" color="#b91c1c" onClick={onClose} />,
    },
    [NotificationStatus.CUSTOM]: {
      className: 'notification--custom',
      icon: <Icon name={ICONS.ERROR_CIRCLE} size={IconSize.LG} />,
      closeIcon: <FontAwesomeIcon className="notification__close-icon" icon={faXmark} size="1x" color="#b91c1c" onClick={onClose} />,
    },
  };

  return (
    <div className={`notification ${className} ${notificationStatus[status].className}`}>
      {status === NotificationStatus.CUSTOM && (
        <>
          <div className="notification--custom-header">
            <div className="notification--custom-title">{title}</div>
            <div className="notification--custom-close">
              <div
                className="notification--custom-expand"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpand(!isExpand);
                }}
              >
                <Icon name={isExpand ? ICONS.EXPAND_MORE : ICONS.EXPAND_LESS} size={IconSize.MD} />
              </div>
              {onClose && notificationStatus[status].closeIcon}
            </div>
          </div>
          <div className={`notification--custom-body--${isExpand ? 'show' : 'hide'}`}>{message}</div>
        </>
      )}

      {status !== NotificationStatus.CUSTOM && (
        <>
          <div className="notification__icon">{notificationStatus[status].icon}</div>
          <div className="notification__body">
            <div className="notification__wrapper">
              <span className="notification__content">{message}</span>
            </div>
            {onClose && notificationStatus[status].closeIcon}
          </div>
        </>
      )}
    </div>
  );
};

export default Notification;
