import { InfluencerDTO } from '@services/types/apiType';
import { PaginationConnection, PaginationQueryParams, PagingConnection, PagingQueryParams } from '@services/types/common';
import { AxiosInstance } from 'axios';

const influencerPath = 'influencer';

export default class InfluencerServices {
  constructor(private axios: AxiosInstance) {}

  getInfluencers = async (params: PaginationQueryParams): Promise<PaginationConnection<InfluencerDTO>> => {
    const { data } = await this.axios.get(`${influencerPath}/`, { params });
    return data;
  };

  getInfluencersWithPaging = async (params: PagingQueryParams): Promise<PagingConnection<InfluencerDTO>> => {
    const { data } = await this.axios.get(`${influencerPath}/`, { params });
    return data;
  };
}
