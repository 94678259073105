import { MessageType } from '@common/enum';
import { ChatDTO, MessageDTO } from '@services/types/apiType';
import { PaginationConnection, PaginationQueryParams } from '@services/types/common';
import { AxiosInstance } from 'axios';

const chat = 'chat/';

export default class ChatService {
  constructor(private axios: AxiosInstance) {}

  getInfinityListChat = async (params: PaginationQueryParams): Promise<PaginationConnection<ChatDTO>> => {
    const { data } = await this.axios.get(`${chat}`, { params });
    return data;
  };

  getInfinityListMessage = async (params: { query: PaginationQueryParams; chatId: string }): Promise<PaginationConnection<MessageDTO>> => {
    const { data } = await this.axios.get(`${chat}message/${params.chatId}`, { params: params.query });
    return data;
  };

  checkNewChat = async (params: { influencerId: string; brandId: string }): Promise<{ chat: ChatDTO; isNew: boolean }> => {
    const { data } = await this.axios.post(`${chat}check-new`, params);
    return data;
  };

  sendVideoMessage = async (params: { chatId: string; userSent: string; type: MessageType; video: File }): Promise<MessageDTO> => {
    const bodyFormData = new FormData();
    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined && key !== 'video') {
        bodyFormData.append(key, value as any);
      }
    }
    if (params.video) {
      bodyFormData.append('video', params.video);
    }

    const { data } = await this.axios.post(`${chat}message/video`, bodyFormData, { timeout: 60000 });
    return data;
  };

  sendImagesMessage = async (params: { chatId: string; userSent: string; type: MessageType; images: File[] }): Promise<MessageDTO> => {
    const bodyFormData = new FormData();
    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined && key !== 'images') {
        bodyFormData.append(key, value as any);
      }
    }
    if (params.images.length) {
      for (const image of params.images) {
        bodyFormData.append('images', image);
      }
    }
    const { data } = await this.axios.post(`${chat}message/images`, bodyFormData, { timeout: 60000 });
    return data;
  };
}
