import { createSelector } from '@reduxjs/toolkit';
import { CATEGORIES_INDEXED } from '@common/utils/categories';
import { RootState } from '../../../store';
import i18n from '../../../i18n';

const selectUser = (state: RootState) => state.user;
const selectLanguage = (state: RootState) => state.user.current?.lang;

export const selectCurrentUser = createSelector(selectUser, selectLanguage, (user, language) => {
  const categoriesList = CATEGORIES_INDEXED.GET.filter((cat) => user?.current?.category?.includes(cat.value)).map((category) => ({
    value: category.value,
    label: i18n.t(`category.item.${category.value}`, {
      lng: language,
    }),
  }));
  return {
    ...user.current,
    category: categoriesList,
  };
});
export const selectFbProfileUrl = createSelector(selectUser, (user) => user.fbProfileUrl);
export const selectInsProfileUrl = createSelector(selectUser, (user) => user.insProfileUrl);
export const selectSampleWorks = createSelector(selectUser, (user) => user.sampleWorks);
export const selectUserCurrency = createSelector(selectUser, (user) => user.current?.currency);
