import React from 'react';
import { routes } from '@common/utils/routes';
import './SignOnBonusBanner.scss';
import { useTranslation } from 'react-i18next';
import LocalizedLink from '@components/LocalizeLink/LocalizeLink';

const SignOnBonusBanner = () => {
  const { t } = useTranslation();
  return (
    <div className="sign-on-bonus-banner">
      {t('signOnBonusBanner.1')} <span style={{ fontWeight: 700 }}>{t('signOnBonusBanner.2')}</span> {t('signOnBonusBanner.3')}{' '}
      <LocalizedLink className="sign-on-bonus-banner__link" to={routes.SIGN_UP}>
        {t('signOnBonusBanner.4')}{' '}
      </LocalizedLink>
      {t('signOnBonusBanner.5')}
    </div>
  );
};

export default SignOnBonusBanner;
