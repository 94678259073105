import { notify } from '@common/utils/notify';
import { routes } from '@common/utils/routes';
import { storage, storageKey } from '@common/utils/storage';
import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { LoginForm } from '@views/login/LoginView';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../apiServices';
import { setLoading } from '../common/CommonSlice';
import { authActions } from './AuthActions';
import { setCurrentUser } from '../user/UserSlice';

function* brandsLoginSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const data = yield call(api.auth.brandsLogin, action.payload);
    if (data) {
      storage.setToken(data.token);
      yield put(setCurrentUser(data));
      yield put(push(`/${data.user.lang}/${routes.DEFAULT}`));
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}
function* adminLoginSaga(action: PayloadAction<LoginForm>): any {
  try {
    yield put(setLoading(true));
    const data = yield call(api.admin.adminLogin, action.payload);
    if (data) {
      storage.setToken(data.token);
      window.location.reload();
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* influencerLoginSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const data = yield call(api.auth.influencerLogin, action.payload);
    if (data) {
      storage.setToken(data.token);
      yield put(setCurrentUser(data));
      yield put(push(`/${data.user.lang}/${routes.DEFAULT}`));
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* brandsSignUpSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const data = yield call(api.auth.brandsSignUp, action.payload);
    storage.setToken(data);
    storage.setKeyValue(storageKey.IS_SIGN_UP_SUCCESS, true);
    storage.setKeyValue(storageKey.IS_NEW_USER, true);
    window.location.reload();
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* influencerSignUpSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const data = yield call(api.auth.influencerSignUp, action.payload);
    storage.setToken(data);
    storage.setKeyValue(storageKey.IS_SIGN_UP_SUCCESS, true);
    storage.setKeyValue(storageKey.IS_NEW_USER, true);
    window.location.reload();
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* resetPasswordSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const data = yield call(api.auth.resetPassword, action.payload);
    if (data) {
      yield put(push(routes.LOGIN));
      notify.success('We sent a new password to your email. Please log in with the new password.');
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* changePasswordSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const data = yield call(api.auth.changePassword, action.payload);
    if (data) {
      yield put(push(routes.MY_PROFILE));
      notify.success('Change password successfully.');
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* authSaga() {
  yield all([
    takeLatest(authActions.brandsLogin, brandsLoginSaga),
    takeLatest(authActions.brandsSignUp, brandsSignUpSaga),
    takeLatest(authActions.influencerLogin, influencerLoginSaga),
    takeLatest(authActions.influencerSignUp, influencerSignUpSaga),
    takeLatest(authActions.resetPassword, resetPasswordSaga),
    takeLatest(authActions.changePassword, changePasswordSaga),
    takeLatest(authActions.adminLogin, adminLoginSaga),
  ]);
}
