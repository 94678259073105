import { PAYPAL_INVOICE_FEE, PLATFORM_FEE_RATE, PLATFORM_WITHDRAW_FEE, fixedFee, payoutFixedFee, socialPlatforms } from '@common/constants';
import { AuthType, OrderStatus, WorkTypes } from '@common/enum';
import { ApplicantsDTO, CampaignDTO, CampaignOrderDTO, OrderDTO } from '@services/types/apiType';
import _ from 'lodash';
import dayjs from 'dayjs';
import i18n from '../../i18n';
import { CATEGORIES_INDEXED } from './categories';

export const formatNumber = (number: number | string) => {
  if (number === null || number === undefined || !String(number).match(/[0-9]/g)) return '';
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formateDate = (date: string) => {
  return new Intl.DateTimeFormat(i18n.language, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }).format(new Date(date));
};

export const calculateColumnCount = (width: string) => {
  switch (width) {
    case 'xxl':
      return 4;
    case 'xl':
      return 3;
    case 'lg':
    case 'md':
      return 2;
    case 'sm':
      return 1;
    default:
      return 1;
  }
};

export const getFileName = (file: string) => {
  return file.split('file/')[1];
};

export const calculateDataChart = (socialType: string, campaign?: Partial<CampaignDTO>) => {
  if (!campaign) {
    return 0;
  }

  const insPostPrice = _.sum(campaign.applicant?.filter((app) => app.isHired).map((a) => (a.insPostCost || 0) * a.totalInstagramPost));
  const insStoryPrice = _.sum(campaign.applicant?.filter((app) => app.isHired).map((a) => (a.insStoryCost || 0) * a.totalInstagramStory));
  const fbPostPrice = _.sum(campaign.applicant?.filter((app) => app.isHired).map((a) => (a.fbPostCost || 0) * a.totalFacebookPost));
  const fbReelPrice = _.sum(campaign.applicant?.filter((app) => app.isHired).map((a) => (a.fbReelCost || 0) * a.totalFacebookReel));
  const TikTokPostPrice = _.sum(campaign.applicant?.filter((app) => app.isHired).map((a) => (a.ttPostCost || 0) * a.totalTikTokPost));
  const xPostPrice = _.sum(campaign.applicant?.filter((app) => app.isHired).map((a) => (a.xPostCost || 0) * a.totalXPost));

  const fbPrice = _.sum(
    campaign.applicant
      ?.filter((app) => app.isHired)
      .map((a) => (a.fbPostCost || 0) * a.totalFacebookPost + (a.fbReelCost || 0) * a.totalFacebookReel),
  );
  const insPrice = _.sum(
    campaign.applicant
      ?.filter((app) => app.isHired)
      .map((a) => (a.insPostCost || 0) * a.totalInstagramPost + (a.insStoryCost || 0) * a.totalInstagramStory),
  );
  const ttPrice = _.sum(campaign.applicant?.filter((app) => app.isHired).map((a) => (a.ttPostCost || 0) * a.totalTikTokPost));
  const xPrice = _.sum(campaign.applicant?.filter((app) => app.isHired).map((a) => (a.xPostCost || 0) * a.totalXPost));
  const hiredPrice = fbPrice + insPrice + ttPrice + xPrice;
  const remainingBudget = Math.max(Number(campaign.budget) - hiredPrice, 0);

  switch (socialType) {
    case socialPlatforms.FACEBOOK.displayName:
      return fbPrice;

    case socialPlatforms.INSTAGRAM.displayName:
      return insPrice;

    case socialPlatforms.TIKTOK.displayName:
      return ttPrice;

    case socialPlatforms.X.displayName:
      return xPrice;

    case socialPlatforms.INSTAGRAM.contentType.POST:
      return insPostPrice;

    case socialPlatforms.INSTAGRAM.contentType.STORY:
      return insStoryPrice;

    case socialPlatforms.FACEBOOK.contentType.POST:
      return fbPostPrice;

    case socialPlatforms.FACEBOOK.contentType.REEL:
      return fbReelPrice;

    case socialPlatforms.TIKTOK.contentType.POST:
      return TikTokPostPrice;

    case socialPlatforms.X.contentType.POST:
      return xPostPrice;

    case 'Hired price':
      return hiredPrice;

    default:
      return remainingBudget;
  }
};

export const isValidHttpUrl = (pathString: string | undefined) => {
  if (!pathString) return false;
  let url;
  try {
    url = new URL(pathString);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

const CurrencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
const currencySymbolRegex = /[^\d.,\s]+/g;

export const formatterPriceAmount = (amount: number): string => {
  return CurrencyFormat.format(amount).replace(currencySymbolRegex, '').trim();
};

export const calculateTotalPrice = (amount: number, currency?: string, order?: OrderDTO | CampaignOrderDTO) => {
  if (!order?.referenceId && order?.status === OrderStatus.SUCCESS) {
    return { total: amount, paypalFee: 0, platformFee: 0 };
  }
  const platformFee = amount * PLATFORM_FEE_RATE;
  // round up to the nearest 1 decimal point;
  const fixedFeeWithCurrency = currency === 'THB' ? fixedFee.THB : fixedFee.USD;
  const total = Math.round(((amount + amount * PLATFORM_FEE_RATE) / (1 - PAYPAL_INVOICE_FEE) + fixedFeeWithCurrency) * 10) / 10;
  const paypalFee = total - (amount + platformFee);
  return { platformFee, total, paypalFee };
};

export const calculateTotalWithdraw = (amount: number, userType: AuthType, userCurrency: string) => {
  const paypalWithdrawFee = userCurrency === 'USD' ? payoutFixedFee.USD : payoutFixedFee.THB;
  const platformWithdrawFee = Math.round(amount * (userType === AuthType.INFLUENCER ? PLATFORM_WITHDRAW_FEE : 0) * 10) / 10;
  const totalWithdraw = amount - paypalWithdrawFee - platformWithdrawFee;
  return { paypalWithdrawFee, platformWithdrawFee, totalWithdraw };
};

export const titleFromWorkTypes = {
  [WorkTypes.FB_POST]: socialPlatforms.FACEBOOK.contentType.POST,
  [WorkTypes.FB_REEL]: socialPlatforms.FACEBOOK.contentType.REEL,
  [WorkTypes.INS_POST]: socialPlatforms.INSTAGRAM.contentType.POST,
  [WorkTypes.INS_STORY]: socialPlatforms.INSTAGRAM.contentType.STORY,
  [WorkTypes.TT_POST]: socialPlatforms.TIKTOK.contentType.POST,
  [WorkTypes.X_POST]: socialPlatforms.X.contentType.POST,
};

export const openPopup = (popupUrl: string) => {
  if (!popupUrl) return;
  const popupOptions = 'width=1000,height=600,left=200,top=100';
  window.open(popupUrl, '_blank', popupOptions);
};

export const getListCategoryContentFromIds = (
  ids?: Array<
    | string
    | {
        label: string;
        value: string;
      }
  >,
): string[] => {
  if (!ids || ids.length === 0) return [];
  if (typeof ids[0] === 'object') {
    return ids.map((item: any) => item.label);
  }
  const listCategoryContent = CATEGORIES_INDEXED.GET.filter((item) => ids.includes(item.value)).map((item) => item.label);
  return listCategoryContent;
};

export const isActiveCampaign = (campaignData?: CampaignDTO) => {
  const now = new Date();
  if (!campaignData) return false;
  const { applicable, applyBy, startDate, isPaid } = campaignData;

  return applicable && isPaid && dayjs(applyBy).isAfter(dayjs()) && dayjs(startDate).isBefore(dayjs());
};

export const getPathName = (localizePathName: string): string => {
  if (localizePathName.includes('/en/')) {
    return localizePathName.replace('/en/', '/');
  }
  if (localizePathName.includes('/th/')) {
    return localizePathName.replace('/th/', '/');
  }
  return localizePathName;
};
