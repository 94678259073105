import { AxiosInstance, AxiosResponse } from 'axios';
import { LoginForm } from '@views/login/LoginView';
import { IAdminForgotPasswordForm } from '@views/forgot-password/AdminForgotPassword';
import { ChangePasswordForm, ResetPasswordForm, SignUpBrandsForm, SignUpInfluencerForm } from '../../types/apiType';

const brandsUrl = 'brands/';
const influencerUrl = 'influencer/';

export default class AuthService {
  constructor(private axios: AxiosInstance) {}

  /* Brands */
  brandsLogin = async ({ email, password }: LoginForm) => {
    const { data } = await this.axios.post(`${brandsUrl}login`, { email: email.toLowerCase(), password });
    return data;
  };

  brandsSignUp = async ({ userName, company, workEmail, category, password, confirmationPassword, currency }: SignUpBrandsForm) => {
    const { data } = await this.axios.post(`${brandsUrl}sign-up`, {
      userName: userName.trim(),
      company: company.trim(),
      workEmail: workEmail.toLowerCase(),
      category: category.map((c) => c.value),
      password,
      confirmationPassword,
      currency,
    });
    return data;
  };

  /* Influencer */
  influencerLogin = async ({ email, password }: LoginForm) => {
    const { data } = await this.axios.post(`${influencerUrl}login`, { email: email.toLowerCase().trim(), password });
    return data;
  };

  influencerSignUp = async ({ fullName, userName, email, category, password, confirmationPassword, currency }: SignUpInfluencerForm) => {
    const { data } = await this.axios.post(`${influencerUrl}sign-up`, {
      fullName: fullName.trim(),
      userName: userName.trim(),
      email: email.toLowerCase(),
      category: category.map((c) => c.value),
      password,
      confirmationPassword,
      currency,
    });
    return data;
  };

  resetPassword = async ({ email, type }: ResetPasswordForm) => {
    const { data } = await this.axios.post(`auth/reset-password`, {
      email: email.toLowerCase(),
      type,
    });
    return data;
  };

  changePassword = async ({ oldPassword, newPassword, confirmationNewPassword }: ChangePasswordForm) => {
    const { data } = await this.axios.put(`auth/change-password`, {
      oldPassword,
      newPassword,
      confirmationNewPassword,
    });
    return data;
  };
}
