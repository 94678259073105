import { IAdminForgotPasswordForm } from '@views/forgot-password/AdminForgotPassword';
import { LoginForm } from '@views/login/LoginView';
import { AxiosInstance } from 'axios';
import { ChangeUserPasswordParams, DeleteAccountParams } from '../../types/apiType';

const adminUrl = '/admin';

export default class AdminService {
  constructor(private axios: AxiosInstance) {}

  adminLogin = async ({ email, password }: LoginForm) => {
    const { data } = await this.axios.post(`${adminUrl}/login`, { email: email.toLowerCase(), password });
    return data;
  };

  changeAdminPassword = async ({ email, oldPw, newPw }: IAdminForgotPasswordForm) => {
    const { data } = await this.axios.patch(`${adminUrl}/change-password`, { email: email.toLowerCase(), oldPw, newPw });
    return data;
  };

  adminDeleteAccount = async (params: DeleteAccountParams) => {
    const { data } = await this.axios.delete(`${adminUrl}/delete`, {
      params,
    });
    return data;
  };

  adminChangeUserPasswod = async (params: ChangeUserPasswordParams) => {
    const { data } = await this.axios.patch(`${adminUrl}/change-user-password`, params);
    return data;
  };
}
