import NavBar from '@components/Navbar/NavBar';
import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import './OnlyNavbar.scss';

export interface OnlyNavbarProps {
  children: React.ReactNode;
}

/**
 *
 * This layout only included NavBar component
 */
const OnlyNavbar: React.FunctionComponent<OnlyNavbarProps> = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="only-navbar-layout">
      <NavBar />
      <div className="only-navbar-layout__content">{children}</div>
    </div>
  );
};

export default OnlyNavbar;
