import {
  ApplicantsDTO,
  CampaignCreation,
  CampaignDTO,
  GetListBrandCampaignParams,
  GetListInfluencerCampaignParams,
  OwnedOnGoingCampaignDTO,
  UpdateCampaignParams,
} from '@services/types/apiType';
import {
  ApplicantsQueryParams,
  CancelCampaignQueryParams,
  PaginationConnection,
  PaginationQueryParams,
  PagingConnection,
  PagingQueryParams,
  UpdateApplicantBody,
} from '@services/types/common';
import { AxiosInstance } from 'axios';

const campaign = 'campaign/';
const brands = 'brands/';
const influencer = 'influencer/';

export default class CampaignService {
  constructor(private axios: AxiosInstance) {}

  createCampaign = async ({ coverPicture, category, relatedImages, ...params }: CampaignCreation): Promise<{ isPaid?: boolean }> => {
    const bodyFormData = new FormData();
    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined) {
        bodyFormData.append(key, value as any);
      }
    }
    if (category) {
      category.forEach((c) => bodyFormData.append('category[]', c));
    }
    if (coverPicture) {
      bodyFormData.append('coverPicture', coverPicture);
    }
    if (relatedImages) {
      for (const image of relatedImages) {
        bodyFormData.append('relatedImages', image);
      }
    }

    const { data } = await this.axios.post(`${campaign}`, bodyFormData);
    return data;
  };

  updateCampaign = async ({ id, coverPicture, category, relatedImages, deleteRelatedImages, ...params }: UpdateCampaignParams) => {
    const bodyFormData = new FormData();
    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined) {
        bodyFormData.append(key, value as any);
      }
    }
    if (category) {
      category.forEach((c) => bodyFormData.append('category[]', c));
    }
    if (coverPicture) {
      bodyFormData.append('coverPicture', coverPicture);
    }
    if (relatedImages) {
      for (const image of relatedImages) {
        bodyFormData.append('relatedImages', image);
      }
    }
    if (deleteRelatedImages) {
      deleteRelatedImages.forEach((i) => bodyFormData.append('deleteRelatedImages[]', i));
    }

    const { data } = await this.axios.put(`${campaign}detail/${id}`, bodyFormData);
    return data;
  };

  getInfinityListBrandCampaign = async (params: GetListBrandCampaignParams): Promise<PaginationConnection<CampaignDTO>> => {
    const { data } = await this.axios.get(`${brands}campaign`, { params });
    return data;
  };

  getInfinityListInfluencerCampaign = async (params: GetListInfluencerCampaignParams): Promise<PaginationConnection<CampaignDTO>> => {
    const { data } = await this.axios.get(`${influencer}campaign`, { params });
    return data;
  };

  getInfinityListOngoingCampaign = async (params: PaginationQueryParams): Promise<PaginationConnection<CampaignDTO>> => {
    const { data } = await this.axios.get(`${campaign}`, { params });
    return data;
  };

  getCampaignsWithPaging = async (params: PagingQueryParams): Promise<PagingConnection<CampaignDTO>> => {
    const { data } = await this.axios.get(`${campaign}/admin`, { params });
    return data;
  };

  getCampaignById = async (campaignId: string): Promise<CampaignDTO> => {
    const { data } = await this.axios.get(`${campaign}detail/${campaignId}`);
    return data;
  };

  getInfinityApplicants = async ({ id, ...params }: ApplicantsQueryParams): Promise<PaginationConnection<ApplicantsDTO>> => {
    const { data } = await this.axios.get(`${brands}applicant/${id}`, { params });
    return data;
  };

  getOwnedOnGoingCampaign = async (params: PagingQueryParams): Promise<PagingConnection<OwnedOnGoingCampaignDTO>> => {
    const { data } = await this.axios.get(`${campaign}ongoing`, { params });
    return data;
  };

  applyCampaign = async (campaignId: string): Promise<null> => {
    const { data } = await this.axios.post(`${influencer}campaign`, { campaignId });
    return data;
  };

  approveApplicant = async (applicantId: string): Promise<null> => {
    const { data } = await this.axios.put(`${brands}applicant/${applicantId}`);
    return data;
  };

  cancelCampaign = async (params: CancelCampaignQueryParams): Promise<null> => {
    const { data } = await this.axios.delete(`${influencer}cancel-campaign`, { params });
    return data;
  };

  updateNumberOfPostApplicant = async ({ applicantId, ...body }: UpdateApplicantBody): Promise<null> => {
    const { data } = await this.axios.put(`${brands}applicant/update/${applicantId}`, { ...body });
    return data;
  };
}
