import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminDTO, BrandDTO, InfluencerDTO } from '../../types/apiType';

export interface UserState {
  current: Partial<InfluencerDTO & BrandDTO & AdminDTO> | undefined;
  fbProfileUrl: string;
  insProfileUrl: string;
  sampleWorks: { permalink_url?: string; permalink?: string; id: string }[];
}

const initialState: UserState = {
  current: undefined,
  fbProfileUrl: '',
  insProfileUrl: '',
  sampleWorks: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<Partial<InfluencerDTO & BrandDTO & AdminDTO>>) => {
      state.current = action.payload;
    },
    setFbProfileUrl: (state, action: PayloadAction<string>) => {
      state.fbProfileUrl = action.payload;
    },
    setInsProfileUrl: (state, action: PayloadAction<string>) => {
      state.insProfileUrl = action.payload;
    },
    setSampleWorks: (state, action: PayloadAction<{ permalink_url?: string; permalink?: string; id: string }[]>) => {
      state.sampleWorks = action.payload;
    },
  },
});

export const { setCurrentUser, setFbProfileUrl, setInsProfileUrl, setSampleWorks } = userSlice.actions;

export default userSlice.reducer;
