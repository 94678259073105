import { createAction } from '@reduxjs/toolkit';
import { LoginForm } from '@views/login/LoginView';
import { ChangePasswordForm, ResetPasswordForm, SignUpBrandsForm, SignUpInfluencerForm } from '@services/types/apiType';

const brands = 'brands/';
const influencer = 'influencer/';
const admin = 'admin/';

export const ActionsTypes = {
  brandsLogin: `${brands}BRANDS_LOGIN_ACTION`,
  brandsLoginSuccess: `${brands}BRANDS_LOGIN_SUCCESS_ACTION`,
  brandsSignUp: `${brands}BRANDS_SIGN_UP_ACTION`,

  influencerLogin: `${influencer}INFLUENCER_LOGIN_ACTION`,
  influencerLoginSuccess: `${influencer}INFLUENCER_LOGIN_SUCCESS_ACTION`,
  influencerSignUp: `${brands}BRANDS_INFLUENCER_ACTION`,

  adminLogin: `${admin}ADMIN_LOGIN_ACTION`,

  resetPassword: 'RESET_PASSWORD',
  changePassword: 'CHANGE_PASSWORD',
};

export const authActions = {
  brandsLogin: createAction<LoginForm>(ActionsTypes.brandsLogin),
  brandsSignUp: createAction<SignUpBrandsForm>(ActionsTypes.brandsSignUp),

  influencerLogin: createAction<LoginForm>(ActionsTypes.influencerLogin),
  influencerSignUp: createAction<SignUpInfluencerForm>(ActionsTypes.influencerSignUp),

  resetPassword: createAction<ResetPasswordForm>(ActionsTypes.resetPassword),
  changePassword: createAction<ChangePasswordForm>(ActionsTypes.changePassword),

  adminLogin: createAction<LoginForm>(ActionsTypes.adminLogin),
};
