import { BrandDTO, CampaignDTO } from '@services/types/apiType';
import {
  CampaignPagingQueryParams,
  PaginationConnection,
  PaginationQueryParams,
  PagingConnection,
  PagingQueryParams,
} from '@services/types/common';
import { AxiosInstance } from 'axios';

const campaign = 'campaign/';
const brands = 'brands/';
const influencer = 'influencer/';
export default class BrandService {
  constructor(private axios: AxiosInstance) {}

  getInfinityListBrand = async (params: PaginationQueryParams): Promise<PaginationConnection<BrandDTO>> => {
    const { data } = await this.axios.get(`${brands}`, { params });
    return data;
  };

  getListBrandPaging = async (params: PagingQueryParams): Promise<PagingConnection<BrandDTO>> => {
    const { data } = await this.axios.get(`${brands}`, { params });
    return data;
  };

  getInfinityCampaignByBrandId = async ({
    params,
    brandId,
  }: {
    params: PaginationQueryParams;
    brandId: string;
  }): Promise<PaginationConnection<CampaignDTO>> => {
    const { data } = await this.axios.get(`${brands}campaign/${brandId}`, {
      params,
    });
    return data;
  };

  getPagingCampaignByBrandId = async ({
    params,
    brandId,
  }: {
    params: CampaignPagingQueryParams;
    brandId: string;
  }): Promise<PagingConnection<CampaignDTO>> => {
    const { data } = await this.axios.get(`${brands}campaign/${brandId}`, {
      params,
    });
    return data;
  };

  getBrandById = async (brandId: string): Promise<BrandDTO> => {
    const { data } = await this.axios.get(`${brands}/${brandId}`);
    return data;
  };
}
