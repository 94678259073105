import { AuthType, BalanceRequestType } from '@common/enum';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CommonState {
  loading: boolean;
  userType: AuthType;
  totalUnreadMessage: number;
  onlineUser: string[];
  postIdSelectedReview: string;
  imageSelectedReview: string;
  ui: {
    dialog: {
      [name: string]: boolean;
    };
  };
  downloadFiles: { [id: string]: { id: string; title: string; data: any[]; progress: number; downloading: boolean } };
}

const initialState: CommonState = {
  loading: false,
  userType: AuthType.BRANDS,
  totalUnreadMessage: 0,
  onlineUser: [],
  imageSelectedReview: '',
  postIdSelectedReview: '',
  ui: { dialog: {} },
  downloadFiles: {},
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    toggleUserType: (state, action: PayloadAction<AuthType>) => {
      state.userType = action.payload;
    },
    toggleModals: (state, action: PayloadAction<string>) => {
      state.ui.dialog = { ...state.ui.dialog, [action.payload]: !state.ui.dialog[action.payload] };
    },
    closeModals: (state, action: PayloadAction<string>) => {
      state.ui.dialog = { ...state.ui.dialog, [action.payload]: false };
    },
    setTotalUnreadMessage: (state, action: PayloadAction<number>) => {
      state.totalUnreadMessage = action.payload;
    },
    setOnlineUser: (state, action: PayloadAction<string[]>) => {
      state.onlineUser = action.payload;
    },
    setImageSelectedReview: (state, action: PayloadAction<string>) => {
      state.imageSelectedReview = action.payload;
    },
    setPostIdSelectedReview: (state, action: PayloadAction<string>) => {
      state.postIdSelectedReview = action.payload;
    },
    setDownloadFile: (state, { payload: { id, title, data } }) => {
      state.downloadFiles[id] = { id, title, data, progress: 0, downloading: false };
    },
    updateDownloadStatus: (state, { payload: { id, key, value } }) => {
      // key = "progress" or "downloading"
      (state.downloadFiles[id] as any)[key] = value;
    },
    removeDownloaded: (state, { payload }: { payload: string }) => {
      delete state.downloadFiles[payload];
    },
  },
});

export const {
  setLoading,
  toggleUserType,
  toggleModals,
  closeModals,
  setTotalUnreadMessage,
  setOnlineUser,
  setImageSelectedReview,
  setPostIdSelectedReview,
  setDownloadFile,
  updateDownloadStatus,
  removeDownloaded,
} = commonSlice.actions;

export default commonSlice.reducer;
