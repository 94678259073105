import { AuthType } from '@common/enum';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const storageKey = {
  TOKEN: 'token',
  IS_SIGN_UP_SUCCESS: 'isSignUpSuccess',
  IS_NEW_USER: 'isNewUser',
};

export const storage = {
  setToken: (token: string) =>
    cookies.set(storageKey.TOKEN, token, {
      path: '/',
    }),

  getToken: () => cookies.get(storageKey.TOKEN),

  removeToken: () => cookies.remove(storageKey.TOKEN, { path: '/' }),

  setKeyValue: (key: string, value: unknown) => cookies.set(key, value, { path: '/' }),

  getKeyValue: (key: string) => cookies.get(key),

  removeStorageKey: (key: string) => cookies.remove(key, { path: '/' }),

  get isAdminLogged() {
    const token = this.getToken();
    const data: any = token && jwtDecode(token);
    return AuthType.ADMIN === data?.type || AuthType.Staff === data?.type;
  },

  get isLoggedIn() {
    return !!this.getToken();
  },
};
