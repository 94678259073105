import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import campaignSlice from '@services/controllers/campaign/CampaignSlice';
import chatSlice from '@services/controllers/chat/ChatSlice';
import balanceRecordSlice from '@services/controllers/balanceRequest/BalanceRequestSlice';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import ExchangeSlice from '@services/controllers/exchange/ExchangeSlice';
import rootSaga from './saga';
import authSlice from './services/controllers/auth/AuthSlice';
import CommonSlice from './services/controllers/common/CommonSlice';
import orderSlice from './services/controllers/order/OrderSlice';
import userSlice from './services/controllers/user/UserSlice';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middleware = [...getDefaultMiddleware({ thunk: false, serializableCheck: false }), sagaMiddleware, routerMiddleware(history)];

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['common', 'user', 'campaign', 'order'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    router: connectRouter(history),
    auth: authSlice,
    common: CommonSlice,
    user: userSlice,
    campaign: campaignSlice,
    chat: chatSlice,
    order: orderSlice,
    balanceRecord: balanceRecordSlice,
    exchange: ExchangeSlice,
  }),
);

const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
