import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OrderDTO } from '@services/types/apiType';

const initialState: {
  brandOrderSelected?: OrderDTO;
  hasNewOrder: boolean;
} = {
  brandOrderSelected: undefined,
  hasNewOrder: false,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setSelectedBrandOrder: (state, action: PayloadAction<OrderDTO | undefined>) => {
      state.brandOrderSelected = action.payload;
    },
    setHasNewOrder: (state, action: PayloadAction<boolean>) => {
      state.hasNewOrder = action.payload;
    },
  },
});

export const { setSelectedBrandOrder, setHasNewOrder } = orderSlice.actions;

export default orderSlice.reducer;
