import { storage } from '@common/utils/storage';
import * as React from 'react';

import './Admin.scss';

import { Switch, useHistory, useLocation } from 'react-router-dom';
import { lazy } from '@loadable/component';
import { adminGuard, routes } from '@common/utils/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import PrivateRoute from '@components/Route/PrivateRoute';
import NavbarAdmin from './components/NavbarAdmin';

export interface IAdminViewProps {}
const BrandManage = lazy(() => import('./BrandManage/BrandManage'));
const InfluencerManage = lazy(() => import('./InfluencerManage/InfluencerManage'));
const CampaignManage = lazy(() => import('./CampaignManage/CampaignManage'));
const AdminBrandDetail = lazy(() => import('./AdminBrandDetail/AdminBrandDetail'));
const CampaignDetail = lazy(() => import('../campaign-detail/CampaignDetail'));
const InfluencerDetail = lazy(() => import('../my-profile/MyProfile'));

const adminNestedRoutes = [
  {
    path: routes.ADMIN_BRANDS,
    title: 'CMS Admin',
    component: BrandManage,
    guards: [adminGuard],
  },
  {
    path: routes.ADMIN_INFLUENCERS,
    title: 'CMS Admin',
    component: InfluencerManage,
    guards: [adminGuard],
  },
  {
    path: routes.ADMIN_CAMPAIGNS,
    title: 'CMS Admin',
    component: CampaignManage,
    guards: [adminGuard],
  },
  {
    path: routes.ADMIN_BRAND_DETAIL,
    title: 'CMS Brand detail',
    component: AdminBrandDetail,
    guards: [adminGuard],
  },
  {
    path: routes.ADMIN_CAMPAIGN_DETAIL,
    title: 'CMS Campaign detail',
    component: CampaignDetail,
    guards: [adminGuard],
  },
  {
    path: routes.ADMIN_INFLUENCER_DETAIL,
    title: 'CMS Influencer detail',
    component: InfluencerDetail,
    guards: [adminGuard],
  },
];

export default function AdminView(props: IAdminViewProps) {
  const { i18n } = useTranslation();
  const history = useHistory();
  React.useEffect(() => {
    i18n.changeLanguage('en');
  }, []);
  return (
    <div className="admin">
      <div className="admin_nav">
        <NavbarAdmin />
      </div>
      <div className="admin_main">
        <div className="admin_main-back" onClick={() => history.goBack()}>
          <FontAwesomeIcon size="xl" icon={faArrowLeft} />
          <h2> Back</h2>
        </div>
        <Switch>
          {adminNestedRoutes.map((route) => {
            const Page = route.component;
            return <PrivateRoute exact key={route.path} path={`${route.path}`} component={() => <Page />} guards={[...route.guards]} />;
          })}
        </Switch>
      </div>
    </div>
  );
}
