import { WithdrawHistoryDTO } from '@services/types/apiType';
import { PagingConnection, PagingQueryParams } from '@services/types/common';
import { AxiosInstance } from 'axios';

const withdrawHistory = 'withdraw-history/';

export default class WithdrawService {
  constructor(private axios: AxiosInstance) {}

  getWithdrawHistory = async (params: PagingQueryParams): Promise<PagingConnection<WithdrawHistoryDTO>> => {
    const { data } = await this.axios.get(`${withdrawHistory}`, { params });
    return data;
  };

  withdrawMoney = async (params: { withdrawAmount: number; withdrawDate: Date }): Promise<null> => {
    const { data } = await this.axios.post(`${withdrawHistory}`, params);
    return data;
  };
}
