import { storage } from './storage';

export const routes = {
  DEFAULT: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  SIGN_UP: '/sign-up',
  ABOUT_US: '/about-us',
  CONTACT: '/contact',
  TERM_OF_USE: '/term-of-use',
  MY_PROFILE: '/my-profile',
  PROFILE: '/profile/:profileId',
  EDIT_PROFILE: '/edit-profile',
  POLICY_PRIVACY: '/policy-privacy',
  MY_CAMPAIGN: '/campaigns',
  CAMPAIGN_DETAIL: '/campaign-detail/:campaignId',
  CREATE_CAMPAIGN: '/create-campaign',
  EDIT_CAMPAIGN: '/edit-campaign/:campaignId',
  MESSAGE: '/message',
  ORDERS: '/orders',
  CHECKOUT: '/checkout/:orderId',
  BALANCE: '/balance',
  BROWSE_BRAND: '/brands',
  BRAND_DETAIL: '/brand-detail/:brandId',
  BROWSE_INFLUENCER: '/influencers',

  ADMIN: '/admin',
  ADMIN_LOGIN: '/admin/login',
  ADMIN_FORGOT_PASSWORD: '/admin/forgot-password',
  ADMIN_DASHBOARD: '/admin/dashboard',
  ADMIN_BRANDS: '/admin/brands',
  ADMIN_INFLUENCERS: '/admin/influencers',
  ADMIN_CAMPAIGNS: '/admin/campaigns',
  ADMIN_BRAND_DETAIL: '/admin/brands/:brandId',
  ADMIN_CAMPAIGN_DETAIL: '/admin/campaigns/:campaignId',
  ADMIN_INFLUENCER_DETAIL: '/admin/influencers/:profileId',
};

export interface RouteGuard {
  /**
   * If the condition is not met then either redirect to onFail or don't render the route
   */
  failCondition: boolean;
  /**
   * If request is still in progress we don't want to call onFail yet
   */
  requestDone: boolean;
  /**
   * URL where to redirect to, when condition is not met
   */
  onFail?: string | null;
}

export const unAuthGuard: RouteGuard = {
  get failCondition() {
    return !!storage.isLoggedIn;
  },
  requestDone: true,
  onFail: storage.isAdminLogged ? routes.ADMIN : routes.DEFAULT,
};

export const authGuard: RouteGuard = {
  get failCondition() {
    return !storage.isLoggedIn;
  },
  requestDone: true,
  onFail: `/en${routes.LOGIN}`,
};

export const adminGuard: RouteGuard = {
  get failCondition() {
    return !storage.isAdminLogged;
  },
  requestDone: true,
  onFail: routes.ADMIN_LOGIN,
};
