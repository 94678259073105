import {
  ApplicantsDTO,
  CampaignDTO,
  GetBrandCampaignWithOrderParams,
  OrderDetailResponse,
  OrderPaypalInvoiceDTO,
} from '@services/types/apiType';
import { PagingConnection } from '@services/types/common';
import { AxiosInstance } from 'axios';

const order = 'order';

export default class OrderService {
  constructor(private axios: AxiosInstance) {}

  getPaginationCampaignWithOrder = async (params: GetBrandCampaignWithOrderParams): Promise<PagingConnection<CampaignDTO>> => {
    const { data } = await this.axios.get(`${order}/brand`, { params });
    return data;
  };

  getPaginationInfluencerOrder = async (params: GetBrandCampaignWithOrderParams): Promise<PagingConnection<ApplicantsDTO>> => {
    const { data } = await this.axios.get(`${order}/influencer`, { params });
    return data;
  };

  getOrderDetail = async (id: string): Promise<OrderDetailResponse> => {
    const { data } = await this.axios.get(`${order}/${id}`);
    return data;
  };

  checkoutOrder = async (id: string): Promise<OrderPaypalInvoiceDTO> => {
    const { data } = await this.axios.post(`${order}/checkout/${id}`);
    return data;
  };

  cancelOrder = async (id: string): Promise<OrderPaypalInvoiceDTO> => {
    const { data } = await this.axios.put(`${order}/${id}`);
    return data;
  };
}
