import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ChatDTO } from '@services/types/apiType';

const initialState: {
  chatSelected?: ChatDTO;
} = {
  chatSelected: undefined,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatSelected: (state, action: PayloadAction<ChatDTO | undefined>) => {
      state.chatSelected = action.payload;
    },
  },
});

export const { setChatSelected } = chatSlice.actions;

export default chatSlice.reducer;
