import Button, { ButtonType } from '@components/Button/Button';
import { ICONS } from '@components/SVG/Icon';
import * as React from 'react';
import './NavbarAdmin.scss';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { routes } from '@common/utils/routes';

export interface INavbarAdminProps {}

export default function NavbarAdmin(props: INavbarAdminProps) {
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <div className="navbar_adm">
      {/* <NavLink className="navbar_adm__link" activeClassName="menu-active" to={routes.ADMIN_DASHBOARD}>
        <img src={ICONS.SETTING_ADJUST} alt="" className="navbar_adm__link-icon" />
        <div className="navbar_adm__link-title">Dashboard</div>
      </NavLink> */}

      <NavLink className="navbar_adm__link" activeClassName="menu-active" to={routes.ADMIN_BRANDS}>
        <img src={ICONS.BRAND} alt="" className="navbar_adm__link-icon" />
        <div className="navbar_adm__link-title">Brands</div>
      </NavLink>
      <NavLink className="navbar_adm__link" activeClassName="menu-active" to={routes.ADMIN_INFLUENCERS}>
        <img src={ICONS.INFLUENCER} alt="" className="navbar_adm__link-icon" />
        <div className="navbar_adm__link-title">Influencers</div>
      </NavLink>

      <NavLink className="navbar_adm__link" activeClassName="menu-active" to={routes.ADMIN_CAMPAIGNS}>
        <img src={ICONS.CAMPAIGN} alt="" className="navbar_adm__link-icon" />
        <div className="navbar_adm__link-title">Campaigns</div>
      </NavLink>
    </div>
  );
}
