import { createContext } from 'react';
import { io } from 'socket.io-client';

const { REACT_APP_SERVER_HOST } = process.env;

export const socket = io(REACT_APP_SERVER_HOST || '', { autoConnect: false, path: '/api/v1/socket.io', transports: ['websocket'] });

export const SocketContext = createContext(socket);

export const SocketProvider = SocketContext.Provider;
export const SocketConsumer = SocketContext.Consumer;
