import React from 'react';
import { NavLink, useParams } from 'react-router-dom';

const LocalizedNavLink = ({ to, ...props }: any) => {
  const { lang } = useParams<any>();

  const localizedPath = `/${lang}${to}`;

  return <NavLink to={localizedPath} {...props} />;
};

export default LocalizedNavLink;
