import {
  AuthType,
  BalanceChangeRequestStatus,
  BalanceRequestType,
  Gender,
  MessageType,
  OrderStatus,
  OrderType,
  PayExtraBudgetSource,
  WorkImageStatus,
  WorkTypes,
  FollowerCountRange,
} from '@common/enum';
import { PaginationQueryParams, PagingQueryParams } from './common';

export enum Role {
  ADMIN = 'admin',
  MEMBER = 'member',
}

export enum CurrencyType {
  USD = 'USD',
  THB = 'THB',
}

export interface InfluencerDTO {
  id: string;
  fullName: string;
  userName: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
  country: string;
  category: string[];
  description: string;
  avatar?: File | string;
  bankAccountNumber: number;
  bankAccountHolderName: string;
  bankName: string;
  bankAddress: string;
  bankAccountAddress: string;
  fbFollowers: FollowerCountRange;
  insFollowers: FollowerCountRange;
  ttFollowers?: FollowerCountRange;
  xFollowers?: FollowerCountRange;
  fbUserId: string;
  insUserId: string;
  instagramLink: string;
  facebookLink: string;
  tikTokLink: string;
  xLink: string;
  instagramPostCost: number;
  instagramStoryCost: number;
  facebookPostCost: number;
  facebookReelCost: number;
  tikTokPostCost: number;
  xPostCost: number;
  balance: number;
  signOnBonus: number;
  isBonusLocked: boolean;
  lang?: string;
  currency?: CurrencyType;
  applicant?: ApplicantsDTO[];
}

export interface BrandDTO {
  id: string;
  userName: string;
  company: string;
  workEmail: string;
  category: string[];
  country: string;
  phone: string;
  avatar: File | string;
  description: string;
  instagramLink: string;
  facebookLink: string;
  numberOfCampaignNotView?: number;
  balance: number;
  deposit: number;
  signOnBonus: number;
  isBonusLocked: boolean;
  lang: string;
  createdAt?: Date;
  updatedAt?: Date;
  campaignCount?: number;
  updatedCampaign?: Date;
  campaigns?: CampaignDTO[];
  currency?: CurrencyType;
  activeCampaigns?: number;
}

export interface DTO<Object = any> {
  [id: string]: Object;
}

export interface UserNormalized {
  user: DTO<InfluencerDTO>;
}

export interface SignUpBrandsForm {
  userName: string;
  company: string;
  currency: string;
  workEmail: string;
  category: { label: string; value: string }[];
  password: string;
  confirmationPassword: string;
}

export interface SignUpInfluencerForm {
  fullName: string;
  userName: string;
  email: string;
  currency: string;
  category: { label: string; value: string }[];
  password: string;
  confirmationPassword: string;
}

export interface ResetPasswordForm {
  email: string;
  type: AuthType;
}

export type UpdateUserInfoParams = Partial<InfluencerDTO & BrandDTO> & { type: AuthType };

export interface LongLiveAccessTokenParams {
  shortLiveAccessToken: string;
  userId: string;
  socialType: string;
}

export interface ExtractFollowersParams {
  profileUrl: string;
  socialType: string;
}

export interface ChangePasswordForm {
  oldPassword: string;
  newPassword: string;
  confirmationNewPassword: string;
}

export interface CampaignAttributes {
  id: string;
  title: string;
  coverPicture: File;
  duration: number;
  startDate: Date;
  endDate: Date;
  category: string[];
  url: string;
  hashtag: string;
  keyword: string;
  budget: number;
  description: string;
  relatedImages: File[];
  followersNumber: number;
  age: string;
  gender: Gender;
  startingFrom?: number;
  totalInstagramPost: number;
  totalFacebookPost: number;
  totalInstagramStory: number;
  totalFacebookReel: number;
  totalTikTokPost: number;
  totalXPost: number;
  createdAt?: Date;
  updatedAt?: Date;
  applicable: boolean;
  applyBy?: Date;
}

export type CampaignCreation = Partial<Omit<CampaignAttributes, 'id'>>;

export type UpdateCampaignParams = CampaignCreation & {
  id: string;
  deleteRelatedImages?: string[];
  extraBudgetSource?: PayExtraBudgetSource;
};

export interface CampaignDTO {
  id: string;
  title: string;
  brand: {
    id: string;
    company: string;
    avatar: string;
    userName: string;
    phone: string;
  };
  applicant?: ApplicantsDTO[];
  coverPicture: string;
  duration: number;
  startDate: Date;
  endDate: Date;
  category: Array<
    | string
    | {
        label: string;
        value: string;
      }
  >;
  url: string;
  hashtag: string;
  keyword: string;
  budget: number;
  description: string;
  relatedImages: string[];
  followersNumber: number;
  age: string;
  gender: Gender;
  startingFrom?: number;
  totalInstagramPost: number;
  totalFacebookPost: number;
  totalInstagramStory: number;
  totalFacebookReel: number;
  totalTikTokPost: number;
  totalXPost: number;
  applicable: boolean;
  isPaid: boolean;
  applyBy?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  hiredCount?: number;
  isViewedApplicant?: boolean;
  orders?: CampaignOrderDTO[];
  currency?: CurrencyType;
}

export type CampaignOrderDTO = Pick<OrderDTO, 'id' | 'orderType' | 'priceToPay' | 'startPaymentDate' | 'status' | 'referenceId'>;

export type OwnedOnGoingCampaignDTO = Pick<CampaignDTO, 'title' | 'budget' | 'id' | 'currency'>;
export interface GetListBrandCampaignParams extends PaginationQueryParams {
  isExpired?: boolean;
}

export interface GetListInfluencerCampaignParams extends PaginationQueryParams {
  comingSoon?: boolean;
  isExpired?: boolean;
  isHired?: boolean;
  categories?: string[];
  searchValue?: string;
}
export interface GetListMessageParams extends PaginationQueryParams {
  type?: 'video' | 'image' | 'text';
}

export interface ApplicantsDTO {
  id: string;
  influencerId?: string;
  influencer: InfluencerDTO;
  createdAt: Date;
  updateAt: Date;
  insPostCost?: number;
  insStoryCost?: number;
  fbReelCost?: number;
  fbPostCost?: number;
  ttPostCost?: number;
  xPostCost?: number;
  isHired: boolean;
  isReviewedWork: boolean;
  isUpdatedWork: boolean;
  hiredAt: Date;
  totalFacebookPost: number;
  totalFacebookReel: number;
  totalInstagramPost: number;
  totalInstagramStory: number;
  totalTikTokPost: number;
  totalXPost: number;
  campaign?: CampaignDTO;
  price: number;
  isPaid: boolean;
  paymentStatus: OrderStatus;
  paymentDate?: Date;
  finalWorks?: FinalWorkRes;
  currency?: CurrencyType;
}

export interface ApplicantsTable {
  key: string;
  type: string;
  socialMedia: string;
  cost?: number;
  quantity: string;
  currency?: CurrencyType;
  total: number;
}

export interface DraftWorkQueryParams {
  influencerId?: string;
  applicantId?: string;
  campaignId?: string;
}
export interface CreateDraftWorkParams {
  applicantId: string;
  type: WorkTypes;
  draftImages: File[];
}

export interface DraftWorkDTO {
  id: string;
  type: WorkTypes;
  draftImages: {
    file: string;
    status: WorkImageStatus;
    comment?: string;
  }[];
  caption: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface DraftWorkRes {
  fbPost: DraftWorkDTO[];
  fbReel: DraftWorkDTO[];
  insPost: DraftWorkDTO[];
  insStory: DraftWorkDTO[];
  ttPost: DraftWorkDTO[];
  xPost: DraftWorkDTO[];
}

export interface FinalWorkDTO {
  id: string;
  type: WorkTypes;
  proveLink: string;
  proveImages: string[];
  status: WorkImageStatus;
  comment?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface FinalWorkRes {
  fbPost: FinalWorkDTO[];
  fbReel: FinalWorkDTO[];
  insPost: FinalWorkDTO[];
  insStory: FinalWorkDTO[];
  ttPost: FinalWorkDTO[];
  xPost: FinalWorkDTO[];
}

export interface CreateFinalWorkParams {
  applicantId: string;
  type: WorkTypes;
  proveImages: File[];
  proveLink: string;
}
export interface ChatDTO {
  id: string;
  users: {
    id: string;
    name: string;
    userName: string;
    avatar: string;
  }[];
  lastMessage: string;
  userSentLastMessage: string;
  isSeenLastMessage: boolean;
  lastSentAt: Date;
  createdAt: Date;
  updatedAt: Date;
  isBlockedInfluencer: boolean;
}

export interface MessageDTO {
  id: string;
  chatId: string;
  userSent: string;
  type: MessageType;
  content: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ProfileDetail {
  id?: string;
  type: AuthType;
}

export interface OrderDTO {
  id: string;
  campaignId: string;
  campaign?: Pick<CampaignDTO, 'title' | 'budget' | 'currency'>;
  orderType: OrderType;
  priceToPay: number;
  paymentDate?: Date;
  startPaymentDate: Date;
  status: OrderStatus;
  referenceId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  invoice?: OrderDetailResponse['invoice'];
}

export interface GetBrandCampaignWithOrderParams extends PagingQueryParams {
  isPaid?: boolean;
}

export interface WithdrawHistoryDTO {
  id: string;
  withdrawAmount: number;
  withdrawDate: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface OrderPaypalInvoiceDTO {
  id: string;
  status: 'DRAFT' | 'SENT' | 'PAID' | 'CANCELLED';
  detail: {
    reference: string;
    currency_code: string;
    invoice_number: string;
    invoice_date: string;
    metadata: {
      create_time: string;
      recipient_view_url: string;
    };
  };
  amount: {
    currency_code: string;
    value: string;
  };
  qrCode: string;
}

export type RefundOrderInvoiceDTO = {
  [key in WorkTypes]: {
    amount: number;
    cost: number;
  };
};

export interface OrderDetailResponse {
  order: OrderDTO;
  invoice?: OrderPaypalInvoiceDTO | RefundOrderInvoiceDTO;
}

export interface BalanceChangeRecordDTO {
  id: string;
  userId: string;
  amount: number;
  type: BalanceRequestType; // TODO: Add transfer type when implement feature
  status: BalanceChangeRequestStatus;
  paymentDate?: Date;
  referenceId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  currency?: CurrencyType;
}

export interface GetListCampaignPagingParams extends PagingQueryParams {
  startDate?: Date;
  endDate?: Date;
  comingSoon?: boolean;
  isExpired?: boolean;
}

export interface DeleteAccountParams {
  deleteId: string;
  adminPassword: string;
  type: AuthType;
}

export interface ChangeUserPasswordParams {
  deleteId: string;
  adminPassword: string;
  userNewPassword: string;
  type: AuthType;
}

export enum CAMPAIGN_STATUS_TYPE {
  ON_GOING = 'Ongoing',
  COMING_SOON = 'Coming soon',
  PAST = 'Past',
}

export interface AdminDTO {
  id?: string;
  userName?: string;
  email?: string;
  type?: string;
  createdAt?: string;
  updatedAt?: string;
}
