import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApplicantsDTO, CampaignDTO } from '@services/types/apiType';

const initialState: {
  campaignSelected?: CampaignDTO;
  applicantSelected?: ApplicantsDTO;
} = {
  campaignSelected: undefined,
  applicantSelected: undefined,
};

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaignSelected: (state, action: PayloadAction<CampaignDTO | undefined>) => {
      state.campaignSelected = action.payload;
    },
    setApplicantSelected: (state, action: PayloadAction<ApplicantsDTO | undefined>) => {
      state.applicantSelected = action.payload;
    },
  },
});

export const { setCampaignSelected, setApplicantSelected } = campaignSlice.actions;

export default campaignSlice.reducer;
