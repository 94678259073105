import { all, call, put, takeLatest } from 'redux-saga/effects';
import { notify } from '@common/utils/notify';
import { get } from 'lodash';
import api from '@services/apiServices';
import { exchangeActions } from './ExchangeAction';
import { setExchangeRate } from './ExchangeSlice';

function* getExchangeRateSaga(): any {
  try {
    const data = yield call(api.exchange.getExchangeRate);
    if (data) {
      yield put(setExchangeRate(data));
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

export function* exchangeSaga() {
  yield all([takeLatest(exchangeActions.getExchangeRate, getExchangeRateSaga)]);
}
