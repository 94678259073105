import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

const selectCommon = (state: RootState) => state.common;

export const selectLoading = createSelector(selectCommon, (common) => common.loading);
export const selectUserType = createSelector(selectCommon, (common) => common.userType);
export const selectModalStatus = createSelector(selectCommon, (common) => common.ui.dialog);
export const selectTotalUnreadMessage = createSelector(selectCommon, (common) => common.totalUnreadMessage);
export const selectOnlineUser = createSelector(selectCommon, (common) => common.onlineUser);
export const selectImageSelectedReview = createSelector(selectCommon, (common) => common.imageSelectedReview);
export const selectPostIdSelectedReview = createSelector(selectCommon, (common) => common.postIdSelectedReview);
export const selectedDownloadFiles = createSelector(selectCommon, (common) => common.downloadFiles);
