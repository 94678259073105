import { socialPlatforms } from '@common/constants';
import { RegisteredDialog } from '@common/enum';
import { routes } from '@common/utils/routes';
import { AnyAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import i18n from 'i18n';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { notify } from '../../../common/utils/notify';
import api from '../../apiServices';
import { InfluencerDTO } from '../../types/apiType';
import { CommonGenerator } from '../../types/common';
import { selectUserType } from '../common/CommonSelector';
import { closeModals, setLoading, toggleUserType } from '../common/CommonSlice';
import { userActions } from './UserActions';
import { selectFbProfileUrl, selectInsProfileUrl } from './UserSelector';
import { setCurrentUser, setSampleWorks } from './UserSlice';

function* getUserDetailSaga(): CommonGenerator<InfluencerDTO, any> | any {
  try {
    const data = yield call(api.user.getUserDetail);
    if (data) {
      yield put(setCurrentUser(data));
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* updateUserSaga(action: AnyAction): any {
  try {
    const data = yield call(api.user.updateProfile, action.payload);
    const payloadArr = Object.keys(action.payload);
    const isUpdateLang = payloadArr.length === 2 && payloadArr.includes('lang');
    if (data) {
      yield put(setCurrentUser(data));
      if (!isUpdateLang) {
        yield put(push(routes.MY_PROFILE));
        notify.success(i18n.t('noti.profile.update.success'));
      }
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getLongLiveAccessTokenSaga(action: AnyAction): any {
  try {
    yield call(api.user.getLongLiveAccessToken, action.payload);
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* extractFollowersSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const userType = yield select(selectUserType);
    const insProfileUrl = yield select(selectInsProfileUrl);
    const fbProfileUrl = yield select(selectFbProfileUrl);
    const data = yield call(api.user.extractFollowers, {
      profileUrl: action.payload === socialPlatforms.INSTAGRAM.key ? insProfileUrl : fbProfileUrl,
      socialType: action.payload,
    });
    if (data) {
      yield put(userActions.getDetail(userType.toLowerCase()));
      if (action.payload === socialPlatforms.INSTAGRAM.key) {
        yield put(closeModals(RegisteredDialog.InstagramProfileUrl));
      } else {
        yield put(closeModals(RegisteredDialog.FacebookProfileUrl));
      }
      notify.success('Connected Successfully');
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(push(routes.MY_PROFILE));
    yield put(setLoading(false));
  }
}

function* getSampleWorksSaga(action: AnyAction): any {
  try {
    const data = yield call(api.user.getSampleWorks, action.payload);
    if (data) {
      yield put(setSampleWorks(data));
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

export function* userSaga() {
  yield all([
    takeLatest(userActions.getDetail, getUserDetailSaga),
    takeLatest(userActions.updateProfile, updateUserSaga),
    takeLatest(userActions.getLongLiveAccessToken, getLongLiveAccessTokenSaga),
    takeLatest(userActions.extractFollowers, extractFollowersSaga),
    takeLatest(userActions.getSampleWorks, getSampleWorksSaga),
  ]);
}
