import { ApplicantStatus } from '@common/enum';
import ApiClient from './apiClient';
import AuthService from './controllers/auth/AuthService';
import BalanceChangeService from './controllers/balanceRequest/BalanceRequestServices';
import BrandService from './controllers/brand/BrandService';
import CampaignService from './controllers/campaign/CampaignService';
import ChatService from './controllers/chat/ChatService';
import ExchangeService from './controllers/exchange/ExchangeService';
import InfluencerServices from './controllers/influencer/InfluencerServices';
import OrderService from './controllers/order/OrderService';
import UserService from './controllers/user/UserService';
import WithdrawService from './controllers/withdraw/WithdrawServices';
import WorkService from './controllers/work/WorkServices';
import AdminService from './controllers/admin/AdminService';

export class ApiService {
  public auth: AuthService;

  public user: UserService;

  public campaign: CampaignService;

  public work: WorkService;

  public chat: ChatService;

  public order: OrderService;

  public withdraw: WithdrawService;

  public balanceChange: BalanceChangeService;

  public brand: BrandService;

  public influencer: InfluencerServices;

  public exchange: ExchangeService;

  public admin: AdminService;

  constructor() {
    this.auth = new AuthService(ApiClient);
    this.user = new UserService(ApiClient);
    this.campaign = new CampaignService(ApiClient);
    this.work = new WorkService(ApiClient);
    this.chat = new ChatService(ApiClient);
    this.order = new OrderService(ApiClient);
    this.withdraw = new WithdrawService(ApiClient);
    this.balanceChange = new BalanceChangeService(ApiClient);
    this.brand = new BrandService(ApiClient);
    this.influencer = new InfluencerServices(ApiClient);
    this.exchange = new ExchangeService(ApiClient);
    this.admin = new AdminService(ApiClient);
  }
}

const api = new ApiService();

export default api;
