import React from 'react';
import { Link, useParams } from 'react-router-dom';

const LocalizedLink = ({ to, ...props }: any) => {
  const { lang } = useParams<any>();
  const localizedPath = `/${lang}${to}`;

  return <Link to={localizedPath} {...props} />;
};

export default LocalizedLink;
