import classNames from 'classnames';
import * as React from 'react';

import Eye from './icons/eye.svg';
import EyeOff from './icons/eye-off.svg';
import Checkbox from './icons/checkbox.svg';
import UnCheckbox from './icons/un-checkbox.svg';
import AngleDown from './icons/angle-down.svg';
import Edit from './icons/edit.svg';
import Megaphone from './icons/megaphone.svg';
import Message from './icons/messager.svg';
import ListBoxes from './icons/list-boxes.svg';
import Logout from './icons/logout.svg';
import Back from './icons/back.svg';
import SettingAdjust from './icons/setting-adjust.svg';
import MegaphoneRed from './icons/megaphone-red.svg';
import ListBoxesRed from './icons/list-boxes-red.svg';
import Close from './icons/close.svg';
import Globe from './icons/globe.svg';
import Exclamation from './icons/exclamation.svg';
import Calendar from './icons/calendar.svg';
import Minus from './icons/minus.svg';
import Plus from './icons/plus.svg';
import ExclamationOutline from './icons/exclamation-outline.svg';
import RedPlus from './icons/red-plus.svg';
import ChevronLeft from './icons/chevron-left.svg';
import ChevronRight from './icons/chevron-right.svg';
import Check from './icons/check.svg';
import CheckOutline from './icons/check-outline.svg';
import CheckOutlineDone from './icons/check-outline-done.svg';
import ChatEnter from './icons/chat-enter.svg';
import Image from './icons/image.svg';
import Video from './icons/video.svg';
import Search from './icons/search.svg';
import Download from './icons/download.svg';
import Balance from './icons/balance.svg';
import Coins from './icons/coins.svg';
import Money from './icons/money.svg';
import MoreHorizontalCircle from './icons/more-horizontal-circle.svg';
import BlockUser from './icons/block-user.svg';
import FlagThai from './icons/thailand.svg';
import FlagVi from './icons/flag-for-vietnam.svg';
import FlagEn from './icons/flag-gb-eng.svg';
import Brand from './icons/brand.svg';
import Influencer from './icons/influencer.svg';
import Campaign from './icons/campaign.svg';
import './Icon.scss';

export const ICONS = {
  EYE: Eye,
  EYE_OFF: EyeOff,
  CHECKBOX: Checkbox,
  UN_CHECKBOX: UnCheckbox,
  ANGLE_DOWN: AngleDown,
  EDIT: Edit,
  MEGAPHONE: Megaphone,
  MESSAGE: Message,
  LIST_BOXES: ListBoxes,
  BALANCE: Balance,
  LOG_OUT: Logout,
  BACK: Back,
  SETTING_ADJUST: SettingAdjust,
  MEGAPHONE_RED: MegaphoneRed,
  LIST_BOXES_RED: ListBoxesRed,
  CLOSE: Close,
  GLOBE: Globe,
  EXCLAMATION: Exclamation,
  CALENDAR: Calendar,
  MINUS: Minus,
  PLUS: Plus,
  EXCLAMATION_OUTLINE: ExclamationOutline,
  RED_PLUS: RedPlus,
  CHEVRON_LEFT: ChevronLeft,
  CHEVRON_RIGHT: ChevronRight,
  CHECK: Check,
  CHECK_OUTLINE: CheckOutline,
  CHECK_OUTLINE_DONE: CheckOutlineDone,
  CHAT_ENTER: ChatEnter,
  IMAGE: Image,
  VIDEO: Video,
  SEARCH: Search,
  DOWNLOAD: Download,
  COINS: Coins,
  MONEY: Money,
  MORE_CIRCLE: MoreHorizontalCircle,
  BLOCK_USER: BlockUser,
  FLAG_THAI: FlagThai,
  FLAG_VI: FlagVi,
  FLAG_EN: FlagEn,
  BRAND: Brand,
  INFLUENCER: Influencer,
  CAMPAIGN: Campaign,
};

export interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Use ICONS constant in Icon.tsx
   */
  name: any;
  size?: IconSize;
  rotation?: number;
}

export enum IconSize {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL',
  XXL = 'XXL',
}

const Icon: React.FC<IconProps> = ({ className, rotation, size = IconSize.SM, name, ...rest }) => {
  const classes = classNames(
    'icon',
    {
      'icon--xs': size === IconSize.XS,
      'icon--sm': size === IconSize.SM,
      'icon--md': size === IconSize.MD,
      'icon--lg': size === IconSize.LG,
      'icon--xl': size === IconSize.XL,
      'icon--xxl': size === IconSize.XXL,
    },
    className,
    rotation ? `icon--rotate-${rotation}` : '',
  );

  return (
    <div className={classes} {...rest}>
      <img src={name} alt={name} />
    </div>
  );
};

export default Icon;
