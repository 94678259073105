import { AxiosInstance } from 'axios';

const exchange = 'exchange';

export default class ExchangeService {
  constructor(private axis: AxiosInstance) {}

  getExchangeRate = async () => {
    const { data } = await this.axis.get(`${exchange}`);
    return data;
  };
}
