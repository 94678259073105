import classNames from 'classnames';
import React from 'react';
import './Button.scss';

export enum ButtonType {
  Default = 'default',
  Primary = 'primary',
  Outline = 'outline',
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: ButtonType;
  className?: string;
  titleClassName?: string;
  title?: string;
  children?: React.ReactNode;
  icon?: string;
  iconClassName?: string;
  hasRedDot?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  className,
  buttonType = ButtonType.Primary,
  title,
  titleClassName,
  children,
  icon,
  iconClassName,
  hasRedDot = false,
  ...rest
}) => {
  const classes = classNames('button', className, {
    'button--default': buttonType === ButtonType.Default,
    'button--primary': buttonType === ButtonType.Primary,
    'button--outline': buttonType === ButtonType.Outline,
    'button--icon': !!icon,
  });

  const titleClasses = classNames('button__title', titleClassName, {
    'button__title--default': buttonType === ButtonType.Default,
    'button__title--primary': buttonType === ButtonType.Primary,
    'button__title--outline': buttonType === ButtonType.Outline,
  });

  return (
    <button type="button" className={classes} {...rest}>
      {!!icon && <img src={icon} alt="button icon" className={iconClassName} />}
      {children || (
        <p className={titleClasses}>
          {title} {hasRedDot && <span className="button--red-dot" />}
        </p>
      )}
    </button>
  );
};

export default Button;
