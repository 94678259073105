import { AuthType } from '@common/enum';
import {
  DeleteAccountParams,
  ExtractFollowersParams,
  LongLiveAccessTokenParams,
  ProfileDetail,
  UpdateUserInfoParams,
} from '@services/types/apiType';
import { ContactForm } from '@views/contact/Contact';
import { AxiosInstance } from 'axios';

const admin = '/admin';

export default class UserService {
  constructor(private axios: AxiosInstance) {}

  getUserDetail = async () => {
    const { data } = await this.axios.get(`user/me`);
    return data;
  };

  getUseSuggestion = async (searchKey: string) => {
    const { data } = await this.axios.get('users/autosuggest', { params: { searchKey } });
    return data;
  };

  sendHelpQuestion = async (values: ContactForm) => {
    const { contactAs, topic, email, message, brand } = values;
    const { data } = await this.axios.post('contact/help-question', {
      contactAs: contactAs.value,
      topic: topic.value,
      email: email.toLowerCase(),
      message,
      brand,
    });
    return data;
  };

  getLongLiveAccessToken = async ({ shortLiveAccessToken, userId, socialType }: LongLiveAccessTokenParams) => {
    const { data } = await this.axios.post('influencer/long-live-access-token', {
      shortLiveAccessToken,
      userId,
      socialType,
    });
    return data;
  };

  getSampleWorks = async (userId: string) => {
    const { data } = await this.axios.get(`influencer/get-sample-works/${userId}`);
    return data;
  };

  extractFollowers = async ({ profileUrl, socialType }: ExtractFollowersParams) => {
    const { data } = await this.axios.post('influencer/extract-followers', {
      profileUrl,
      socialType,
    });
    return data;
  };

  updateProfile = async ({ avatar, category, type, ...params }: UpdateUserInfoParams) => {
    const bodyFormData = new FormData();

    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined) {
        bodyFormData.append(key, value as any);
      }
    }

    if (category) {
      category.forEach((c) => bodyFormData.append('category[]', c));
    }
    if (avatar) {
      bodyFormData.append('avatar', avatar);
    }

    const ns = type === AuthType.BRANDS ? 'brands' : 'influencer';
    const { data } = await this.axios.put(`${ns}/update-profile`, bodyFormData);
    return data;
  };

  getProfileDetail = async ({ id, type }: ProfileDetail) => {
    const ns = type === AuthType.BRANDS ? 'brands' : 'influencer';
    const { data } = await this.axios.get(`${ns}/${id}`);
    return data;
  };
}
