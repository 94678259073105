import i18n from '../../i18n';

export const CATEGORIES = [
  'Fashion and Apparel',
  'Beauty and Cosmetics',
  'Fitness and Wellness',
  'Travel and Tourism',
  'Food and Beverage',
  'Technology and Electronics',
  'Home Decor and Interior Design',
  'Entertainment and Media',
  'Health and Wellness',
  'Sports and Fitness Equipment',
  'Jewelry and Accessories',
  'Luxury Brands',
  'Pet Care and Products',
  'Gaming and Esports',
  'Real Estate and Property',
  'Financial Services and Fintech',
  'Education and E-learning',
  'Baby and Parenting Products',
  'Event Planning and Management',
  'Non-profit and Charity Organizations',
  'Sustainable and Eco-friendly Brands',
  'Fitness Apparel and Activewear',
  'Outdoor and Adventure Brands',
  'Music',
  'Art and Design',
  'Home Appliances and Gadgets',
  'Restaurant and Food Delivery Services',
];

export const CATEGORIES_INDEXED = {
  get GET() {
    return CATEGORIES.map((_, i) => {
      return {
        value: String(i + 1),
        label: i18n.t(`category.item.${i + 1}`),
      };
    });
  },
};
