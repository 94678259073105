import { SocketContext, SocketProvider, socket } from '@common/utils/socket';
import { storage } from '@common/utils/storage';
import DefaultLayout from '@components/Layout/DefaultLayout/DefaultLayout';
import OnlyNavbar from '@components/Layout/OnlyNavbar/OnlyNavbar';
import { lazy } from '@loadable/component';
import { selectOnlineUser, selectUserType } from '@services/controllers/common/CommonSelector';
import { setLoading, setOnlineUser, setTotalUnreadMessage } from '@services/controllers/common/CommonSlice';
import { userActions } from '@services/controllers/user/UserActions';
import { selectCurrentUser } from '@services/controllers/user/UserSelector';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AdminView from '@views/admin/Admin';
import BrandManage from '@views/admin/BrandManage/BrandManage';

import { ConnectedRouter } from 'connected-react-router';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { Fragment, useCallback, useMemo } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { getPathName } from '@common/utils/helpers';
import { adminGuard, authGuard, routes, unAuthGuard } from './common/utils/routes';
import store, { history, persistor } from './store';
import 'dayjs/locale/th';

// Components
import LoadingView from './components/Loading/LoadingModal';
import PrivateRoute from './components/Route/PrivateRoute';

// Scenes
const LoginView = lazy(() => import('./views/login/LoginView'));
const SignUpView = lazy(() => import('./views/sign-up/SignUpView'));
const ForgotPassword = lazy(() => import('./views/forgot-password/ForgotPassword'));
const AdminForgotPassword = lazy(() => import('./views/forgot-password/AdminForgotPassword'));
const OnboardingView = lazy(() => import('./views/onboarding/OnboardingView'));
const AboutUs = lazy(() => import('./views/about-us/AboutUs'));
const LandingPage = lazy(() => import('./views/landing-page/LandingPage'));
const Contact = lazy(() => import('./views/contact/Contact'));
const TermOfUse = lazy(() => import('./views/term-of-use/TermOfUse'));
const MyProfile = lazy(() => import('./views/my-profile/MyProfile'));
const PolicyPrivacy = lazy(() => import('./views/policy-privacy/PolicyPrivacy'));
const EditProfile = lazy(() => import('./views/edit-profile/EditProfile'));
const CampaignView = lazy(() => import('./views/campaign/CampaignView'));
const CampaignDetail = lazy(() => import('./views/campaign-detail/CampaignDetail'));
const CreateCampaign = lazy(() => import('./views/create-campaign/CreateCampaign'));
const ChatView = lazy(() => import('./views/chat/ChatView'));
const OrdersView = lazy(() => import('./views/orders/OrdersView'));
const CheckoutView = lazy(() => import('./views/checkout/CheckoutView'));
const BalanceView = lazy(() => import('./views/balance/BalanceView'));
const BrowseBrand = lazy(() => import('./views/browse-brand/BrowseBrand'));
const BrandDetail = lazy(() => import('./views/brand-detail/BrandDetail'));
const BrowseInfluencer = lazy(() => import('./views/browse-influencer/BrowseInfluencer'));

dayjs.extend(localizedFormat);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
});

const adminRoutes = [
  {
    path: routes.ADMIN,
    title: 'CMS',
    component: AdminView,
    layout: OnlyNavbar,
    guards: [adminGuard],
  },
];

const appRoutes = [
  { path: routes.LOGIN, title: 'Login', component: LoginView, layout: OnlyNavbar, guards: [unAuthGuard] },
  { path: routes.SIGN_UP, title: 'Sign up', component: SignUpView, layout: OnlyNavbar, guards: [unAuthGuard] },
  {
    path: routes.FORGOT_PASSWORD,
    title: 'Forgot password',
    component: ForgotPassword,
    layout: OnlyNavbar,
    guards: [unAuthGuard],
  },
  {
    path: routes.ADMIN_FORGOT_PASSWORD,
    title: 'Forgot password',
    component: AdminForgotPassword,
    layout: OnlyNavbar,
    guards: [unAuthGuard],
  },
  { path: routes.ABOUT_US, title: 'About us', component: AboutUs, layout: DefaultLayout, guards: [] },
  { path: routes.CONTACT, title: 'Contact', component: Contact, layout: DefaultLayout, guards: [] },
  { path: routes.TERM_OF_USE, title: 'Term of Use', component: TermOfUse, layout: DefaultLayout, guards: [] },
  { path: routes.MY_PROFILE, title: 'My profile', component: MyProfile, layout: OnlyNavbar, guards: [authGuard] },
  { path: routes.PROFILE, title: 'Profile detail', component: MyProfile, layout: OnlyNavbar, guards: [authGuard] },
  { path: routes.EDIT_PROFILE, title: 'Edit profile', component: EditProfile, layout: OnlyNavbar, guards: [authGuard] },
  { path: routes.POLICY_PRIVACY, title: 'Policy Privacy', component: PolicyPrivacy, layout: DefaultLayout, guards: [] },
  { path: routes.MY_CAMPAIGN, title: 'My Campaign', component: CampaignView, layout: OnlyNavbar, guards: [authGuard] },
  { path: routes.CAMPAIGN_DETAIL, title: 'Campaign Detail', component: CampaignDetail, layout: OnlyNavbar, guards: [authGuard] },
  { path: routes.CREATE_CAMPAIGN, title: 'Create campaign', component: CreateCampaign, layout: OnlyNavbar, guards: [authGuard] },
  { path: routes.EDIT_CAMPAIGN, title: 'Edit campaign', component: CreateCampaign, layout: OnlyNavbar, guards: [authGuard] },
  { path: routes.MESSAGE, title: 'Message', component: ChatView, layout: OnlyNavbar, guards: [authGuard] },
  { path: routes.ORDERS, title: 'My orders', component: OrdersView, layout: OnlyNavbar, guards: [authGuard] },
  { path: routes.CHECKOUT, title: 'Check out', component: CheckoutView, layout: OnlyNavbar, guards: [authGuard] },
  { path: routes.BALANCE, title: 'My balance', component: BalanceView, layout: OnlyNavbar, guards: [authGuard] },
  {
    path: routes.BROWSE_BRAND,
    title: 'Browse Brand',
    component: BrowseBrand,
    layout: OnlyNavbar,
    guards: [authGuard],
  },
  {
    path: routes.BRAND_DETAIL,
    title: 'Brand Detail',
    component: BrandDetail,
    layout: OnlyNavbar,
    guards: [authGuard],
  },
  {
    path: routes.BROWSE_INFLUENCER,
    title: 'Browse Influencer',
    component: BrowseInfluencer,
    layout: OnlyNavbar,
    guards: [authGuard],
  },
];

const DynamicTitle = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const route = appRoutes.find((r) => r.path === location.pathname);
    if (route) {
      document.title = `Axis Social - ${route.title}`;
    } else {
      document.title = 'Axis Social';
    }
  }, [location]);

  React.useEffect(() => {
    if (storage.isLoggedIn && getPathName(location.pathname) !== routes.DEFAULT) {
      dispatch(userActions.getDetail());
    }
    if (storage.isLoggedIn && storage.isAdminLogged) {
      history.push(routes.ADMIN_BRANDS);
    }
  }, [storage.isLoggedIn, storage.isAdminLogged]);

  return null;
};

const SocketLayer = () => {
  const currentSocket = React.useContext(SocketContext);
  const currentUser = useSelector(selectCurrentUser);
  const userType = useSelector(selectUserType);
  const onlineUser = useSelector(selectOnlineUser);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (storage.getToken() && currentUser?.id) {
      currentSocket.auth = { userId: currentUser.id, userType };
      currentSocket.connect();
      currentSocket.on('users-online', (userIds: string[], totalUnreadMessage: number) => {
        dispatch(setOnlineUser(userIds || []));
        dispatch(setTotalUnreadMessage(totalUnreadMessage));
      });
      currentSocket.on('user-connect', (userId: string) => {
        if (userId) {
          dispatch(setOnlineUser([...onlineUser, userId]));
        }
      });
      currentSocket.on('user-disconnect', (userId) => {
        if (userId) {
          dispatch(setOnlineUser(onlineUser.filter((id) => id !== userId)));
        }
      });
    } else {
      currentSocket.off('user-disconnect');
      currentSocket.off('user-connect');
      currentSocket.off('users-online');
      currentSocket.disconnect();
      dispatch(setTotalUnreadMessage(0));
    }

    return () => {
      currentSocket.off('user-disconnect');
      currentSocket.off('user-connect');
      currentSocket.off('users-online');
      currentSocket.disconnect();
    };
  }, [currentUser?.id]);

  return null;
};

function App() {
  const listRoutes = useMemo(() => {
    return storage.isAdminLogged ? adminRoutes : appRoutes;
  }, [storage.isLoggedIn, storage.isAdminLogged]);

  const lanugageApp = localStorage.getItem('i18nextLng');

  const DefaultComponents = useCallback(() => {
    if (storage.isAdminLogged) {
      return (
        <OnlyNavbar>
          <AdminView />
        </OnlyNavbar>
      );
    }

    if (!storage.isLoggedIn) {
      return (
        <DefaultLayout>
          <LandingPage />
        </DefaultLayout>
      );
    }

    return (
      <OnlyNavbar>
        <OnboardingView />
      </OnlyNavbar>
    );
  }, [storage.isLoggedIn, storage.isAdminLogged]);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <SocketProvider value={socket}>
          <PersistGate loading={null} persistor={persistor}>
            <ToastContainer />
            <LoadingView />
            <React.Suspense fallback={<div />}>
              <ConnectedRouter history={history}>
                <DynamicTitle />
                <Switch>
                  {listRoutes.map((route) => {
                    const Page = route.component;
                    let Layout = DefaultLayout;
                    if (route.layout) {
                      Layout = route.layout;
                    } else if (route.layout === null) {
                      Layout = Fragment;
                    }

                    return (
                      <PrivateRoute
                        key={route.path}
                        path={storage.isAdminLogged ? route.path : `/:lang(en|th)${route.path}`}
                        exact={!(route.path === routes.ADMIN)}
                        component={() => (
                          <Layout>
                            <Page />
                          </Layout>
                        )}
                        guards={route.guards}
                      />
                    );
                  })}
                  <PrivateRoute
                    exact
                    path={routes.ADMIN_LOGIN}
                    key={routes.ADMIN_LOGIN}
                    guards={[]}
                    component={() => (
                      <OnlyNavbar>
                        <LoginView />
                      </OnlyNavbar>
                    )}
                  />
                  <PrivateRoute exact path={`/:lang(en|th)${routes.DEFAULT}`} component={() => <DefaultComponents />} guards={[]} />
                  <Redirect to={storage.isAdminLogged ? '/' : `/${lanugageApp}`} />
                </Switch>
              </ConnectedRouter>
            </React.Suspense>
            <SocketLayer />
          </PersistGate>
        </SocketProvider>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
