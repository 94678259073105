import { BalanceRequestType } from '@common/enum';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BalanceChangeRecordDTO } from '@services/types/apiType';

const initialState: {
  recordSelected?: BalanceChangeRecordDTO;
  balanceRequestType?: BalanceRequestType;
  isWithdrawFromSignOnBonus?: boolean;
} = {
  recordSelected: undefined,
  balanceRequestType: undefined,
  isWithdrawFromSignOnBonus: undefined,
};

export const balanceRecordSlice = createSlice({
  name: 'balanceChangeRecord',
  initialState,
  reducers: {
    setBalanceChangeRecordSelected: (state, action: PayloadAction<BalanceChangeRecordDTO | undefined>) => {
      state.recordSelected = action.payload;
    },
    setBalanceRequestType: (state, action: PayloadAction<BalanceRequestType | undefined>) => {
      state.balanceRequestType = action.payload;
    },
    setIsWithdrawFromSignOnBonus: (state, action: PayloadAction<boolean | undefined>) => {
      state.isWithdrawFromSignOnBonus = action.payload;
    },
  },
});

export const { setBalanceChangeRecordSelected, setBalanceRequestType, setIsWithdrawFromSignOnBonus } = balanceRecordSlice.actions;

export default balanceRecordSlice.reducer;
