import React from 'react';
import { toast, ToastOptions } from 'react-toastify';

import Notification from '@components/Notification/Notification';

export enum NotificationStatus {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  CUSTOM = 'CUSTOM',
}

interface Notify {
  success: NotifyFunction;
  info: NotifyFunction;
  warning: NotifyFunction;
  error: NotifyFunction;
  dismiss: (toastId?: any) => void;
  custom: NotifyFunction;
}

type NotifyFunction = (message: React.ReactNode | string, title?: string, config?: ToastOptions) => void;

const toastOptions: ToastOptions = {
  hideProgressBar: true,
  autoClose: 2000,
  closeOnClick: false,
  closeButton: false,
};

const showToast = (message: any, options: ToastOptions = toastOptions, status: NotificationStatus, title?: string) => {
  toast(({ closeToast }) => <Notification title={title} message={message} status={status} onClose={closeToast} />, {
    ...toastOptions,
    ...options,
  });
};

export const notify: Notify = {
  success: (message = 'Success', title, options) => {
    showToast(message, options, NotificationStatus.SUCCESS, title);
  },
  info: (message, title, options) => {
    showToast(message, options, NotificationStatus.INFO, title);
  },
  warning: (message, title, options) => {
    showToast(message, options, NotificationStatus.WARNING, title);
  },
  error: (message = 'An error occurred. Please try again!', title, options) => {
    showToast(message, options, NotificationStatus.ERROR, title);
  },
  dismiss: (toastId?: any) => {
    toast.dismiss(toastId);
  },
  custom: (message, title, options = { className: 'Toastify__toast--custom', position: toast.POSITION.BOTTOM_LEFT, autoClose: false }) =>
    showToast(message, options, NotificationStatus.CUSTOM, title),
};
