import React from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '@common/assets/images/app-logo-white.svg';
import InsIcon from '@common/assets/images/ins-icon.svg';
import LinkedIcon from '@common/assets/images/linked-icon.svg';
import FBIcon from '@common/assets/images/fb-icon.svg';
import './Footer.scss';
import { routes } from '@common/utils/routes';
import LocalizedLink from '@components/LocalizeLink/LocalizeLink';

function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <nav>
        <div className="footer__logo">
          <img className="footer__logo__image" src={Logo} alt="footer logo" />
          <p className="footer__logo__mail">partnerships@axis-social.com</p>
        </div>
        <div className="footer__link">
          <LocalizedLink to={routes.DEFAULT}>{t('home')}</LocalizedLink>
          <LocalizedLink to={routes.ABOUT_US}>{t('home.footer.about')}</LocalizedLink>
          <LocalizedLink to={routes.TERM_OF_USE}>{t('home.footer.termsOfUse')}</LocalizedLink>
          <LocalizedLink to={routes.POLICY_PRIVACY}>{t('home.footer.privacyPolicy')}</LocalizedLink>
          <LocalizedLink to={routes.CONTACT}>{t('home.footer.contact')}</LocalizedLink>
        </div>
      </nav>
      <div className="footer__social-info">
        <div className="footer__social-info__copyright">{t('home.footer.copyright')}</div>
        <div className="footer__social-info__icon-wrapper">
          <a href="https://www.instagram.com/axis_social/" target="_blank" rel="noreferrer">
            <img className="footer__social-info__icon" src={InsIcon} alt="instagram icon" />
          </a>
          <a href="/" target="_blank" rel="noreferrer">
            <img className="footer__social-info__icon" src={LinkedIcon} alt="linked icon" />
          </a>
          <a href="https://www.facebook.com/axissociall" target="_blank" rel="noreferrer">
            <img className="footer__social-info__icon" src={FBIcon} alt="facebook icon" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
