import { storage, storageKey } from '@common/utils/storage';
import axios from 'axios';

const { REACT_APP_API_ENDPOINT, API_TIME_OUT } = process.env;

const ApiClient = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
  timeout: (API_TIME_OUT && parseInt(API_TIME_OUT, 10)) || 30000,
});

ApiClient.interceptors.request.use(
  (config: any) => {
    const authorizationData = storage.getToken();
    if (authorizationData) {
      config.headers.Authorization = `Bearer ${authorizationData}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

ApiClient.interceptors.response.use(
  (response: any) => {
    return response.data;
  },
  (error: any) => {
    /** Logout when token expired or invalid */
    if (error.response && error.response.status === 401) {
      storage.removeToken();
      storage.removeStorageKey(storageKey.IS_NEW_USER);
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

export default ApiClient;
