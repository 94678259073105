import { Dictionary, EntityId } from '@reduxjs/toolkit';
import { PageInfo } from '../services/types/common';

export interface BaseState<S = any> {
  list: {
    ids: EntityId[];
    entities: Dictionary<S>;
    totalCount: number;
    pageInfo: PageInfo;
  };
}

export enum AuthType {
  BRANDS = 'Brands',
  INFLUENCER = 'Influencers',
  ADMIN = 'Admin',
  Staff = 'Staff',
}

export enum RegisteredDialog {
  FacebookProfileUrl = 'FacebookProfileUrl',
  InstagramProfileUrl = 'InstagramProfileUrl',
  CompleteProfile = 'CompleteProfile',
  HireInfluencer = 'HireInfluencer',
  CheckWorkInfluencer = 'CheckWorkInfluencer',
  BudgetTracking = 'BudgetTracking',
  Comment = 'Comment',
  CommentFinalWork = 'CommentFinalWork',
  OrderDetail = 'OrderDetail',
  Payment = 'Payment',
  BalanceRequest = 'BalanceRequest',
  InfluencerCostDetail = 'InfluencerCostDetail',
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export enum ApplicantStatus {
  HIRED = 'Hired',
  REQUESTED = 'Requested',
}

export enum WorkTypes {
  FB_POST = 'fb_post',
  FB_REEL = 'fb_reel',
  INS_POST = 'ins_post',
  INS_STORY = 'ins_story',
  TT_POST = 'tt_post',
  X_POST = 'x_post',
}

export enum WorkImageStatus {
  REJECT = 'reject',
  PENDING = 'pending',
  APPROVED = 'approved',
}

export enum MessageType {
  TEXT = 'text',
  IMAGE = 'image',
  VIDEO = 'video',
}

export enum OrderStatus {
  SUCCESS = 'success',
  PENDING = 'pending',
  FAILED = 'failed',
  SENT = 'sent',
}
export enum OrderType {
  PAY_IN_ADVANCE = 'pay_in_advance',
  PAY_FOR_EXTRA = 'pay_for_extra',
  REFUND = 'refund',
}

export type BalanceRequestType = BalanceChangeType.DEPOSIT | BalanceChangeType.WITHDRAW;

export enum BalanceChangeType {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  TRANSFER_IN = 'transfer_in',
  TRANSFER_OUT = 'transfer_out',
}

export enum BalanceChangeRequestStatus {
  PAID = 'paid',
  CANCELLED = 'cancelled',
  SENT = 'sent',
}

export enum PayExtraBudgetSource {
  BALANCE = 'balance',
  PAYPAL = 'paypal',
}

export enum FollowerCountRange {
  LESS_THAN_1K = '< 1K',
  FROM_1K_TO_5K = '1K - 5K',
  FROM_5K_TO_10K = '5K - 10K',
  FROM_10K_TO_50K = '10K - 50K',
  FROM_50K_TO_100K = '50K - 100K',
  FROM_100K_TO_500K = '100K - 500K',
  FROM_500K_TO_1M = '500K - 1M',
  MORE_THAN_1M = '> 1M',
}
