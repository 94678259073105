import { useHistory, useParams } from 'react-router-dom';

function useLocalizeNavigate() {
  const history = useHistory();
  const { lang } = useParams<any>();

  const localizedNavigate = (path: string, state?: any, replace = false, nextLang?: string) => {
    let newPath = nextLang ? `/${nextLang}${path}` : `/${lang}${path}`;
    if (path === '/en' || path === '/th') {
      newPath = `/${nextLang}`;
    }
    if (replace) {
      history.replace(newPath, state);
    } else {
      history.push(newPath, state);
    }
  };

  return localizedNavigate;
}

export default useLocalizeNavigate;
