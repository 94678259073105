import { createAction } from '@reduxjs/toolkit';
import { LongLiveAccessTokenParams, UpdateUserInfoParams, UserNormalized } from '../../types/apiType';

export const userActions = {
  getDetail: createAction('user/GET_DETAIL'),
  getDetailSuccess: createAction<UserNormalized>('user/GET_DETAIL_SUCCESS'),
  updateProfile: createAction<UpdateUserInfoParams>('user/UPDATE_PROFILE'),

  getLongLiveAccessToken: createAction<LongLiveAccessTokenParams>('user/GET_LONG_LIVE_ACCESS_TOKEN'),
  extractFollowers: createAction<string>('user/EXTRACT_FOLLOWERS'),
  getSampleWorks: createAction<string>('user/GET_SAMPLE_WORKS'),
};
