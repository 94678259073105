import { ColumnState } from '@components/Table/Table';
import { ICONS } from '@components/SVG/Icon';
import { CAMPAIGN_STATUS_TYPE, CurrencyType } from '@services/types/apiType';
import i18n from '../i18n';

export const KEY = 'key';
export const USER_TYPE = [{ title: i18n.t('common.text.brands') }, { title: i18n.t('common.text.influencers') }];
export const EDIT_TYPE = [{ title: 'General information' }, { title: 'Password' }];

export const GET_DATA = {
  get USER_TYPE() {
    return [{ title: i18n.t('common.text.brands') }, { title: i18n.t('common.text.influencers') }];
  },
  get EDIT_TYPE() {
    return [{ title: i18n.t('editProfile.general.generalInformation') }, { title: i18n.t('common.text.oldPassword') }];
  },
  get applicantsColumns(): ColumnState[] {
    return [
      {
        label: i18n.t('common.text.socialMedia'),
        dataId: 'social_media',
        styles: {
          textTransform: 'none',
          width: '22%',
        },
      },
      {
        label: i18n.t('common.text.cost'),
        dataId: 'cost_unit',
        styles: {
          textTransform: 'none',
          width: '22%',
        },
      },
      {
        label: i18n.t('common.text.quantity'),
        dataId: 'quantity',
        styles: {
          textTransform: 'none',
          width: '22%',
        },
      },
      {
        label: i18n.t('common.text.total'),
        dataId: 'total_unit',
        styles: {
          textTransform: 'none',
          width: '22%',
        },
      },
      {
        label: i18n.t('common.currency'),
        dataId: 'currency',
        styles: {
          textTransform: 'none',
          width: '12%',
        },
      },
    ];
  },
  get rateCardTypes() {
    return {
      FACEBOOK_REEL: i18n.t('common.text.fbReel'),
      FACEBOOK_POST: i18n.t('common.text.fbPost'),
      INSTAGRAM_STORY: i18n.t('common.text.igStory'),
      INSTAGRAM_POST: i18n.t('common.text.igPost'),
      TIKTOK_POST: i18n.t('common.text.tiktokPost'),
      X_POST: i18n.t('common.text.xPost'),
    };
  },
  get balanceChangeHistoryColumns(): ColumnState[] {
    return [
      {
        label: i18n.t('table.balance.date'),
        dataId: 'date',
        styles: {
          textTransform: 'none',
          width: '40%',
        },
      },
      {
        label: i18n.t('table.balance.amount'),
        dataId: 'amount',
        styles: {
          textTransform: 'none',
          width: '20%',
        },
      },
      {
        label: i18n.t('table.balance.type'),
        dataId: 'type',
        styles: {
          textTransform: 'none',
          width: '20%',
        },
      },
      {
        label: i18n.t('table.balance.status'),
        dataId: 'status',
        styles: {
          textTransform: 'none',
          width: '20%',
        },
      },
    ];
  },

  get onGoingCampaignColumns(): ColumnState[] {
    return [
      {
        label: i18n.t('Campaign'),
        dataId: 'campaign',
        styles: {
          textTransform: 'none',
          width: '70%',
        },
      },
      {
        label: i18n.t('Budget'),
        dataId: 'budget',
        styles: {
          textTransform: 'none',
          width: '30%',
        },
      },
    ];
  },
  get brandOrderColumns(): ColumnState[] {
    return [
      {
        label: '',
        dataId: 'campaign',
        styles: {
          textTransform: 'none',
          width: '5%',
        },
      },
      {
        label: i18n.t('orders.table.des'),
        dataId: 'description',
        styles: {
          textTransform: 'none',
          width: '35%',
        },
      },
      {
        label: i18n.t('orders.table.billingGate'),
        dataId: 'date',
        styles: {
          textTransform: 'none',
          width: '19%',
          textAlign: 'center',
        },
      },
      {
        label: i18n.t('common.text.total'),
        dataId: 'total_unit',
        styles: {
          textTransform: 'none',
          width: '14%',
          textAlign: 'center',
        },
      },
      {
        label: i18n.t('orders.table.status'),
        dataId: 'status',
        styles: {
          textTransform: 'none',
          width: '12%',
          textAlign: 'center',
        },
      },
      {
        label: '',
        dataId: 'action',
        styles: {
          textTransform: 'none',
          width: '15%',
          textAlign: 'center',
        },
      },
    ];
  },
  get socialPlatforms() {
    return {
      FACEBOOK: {
        key: 'FACEBOOK',
        displayName: 'Facebook',
        contentType: {
          POST: i18n.t('common.text.fbPost'),
          REEL: i18n.t('common.text.fbReel'),
        },
      },
      INSTAGRAM: {
        key: 'INSTAGRAM',
        displayName: 'Instagram',
        contentType: {
          POST: i18n.t('common.text.igPost'),
          STORY: i18n.t('common.text.igStory'),
        },
      },
      TIKTOK: {
        key: 'TIKTOK',
        displayName: 'TikTok',
        contentType: {
          POST: i18n.t('common.text.tiktokPost'),
        },
      },
      X: {
        key: 'X',
        displayName: 'X',
        contentType: {
          POST: i18n.t('common.text.xPost'),
        },
      },
    };
  },
  get orderColumns(): ColumnState[] {
    return [
      {
        label: '',
        dataId: 'no',
        styles: {
          textTransform: 'none',
          width: '6%',
        },
      },
      {
        label: i18n.t('Campaign'),
        dataId: 'campaign',
        styles: {
          textTransform: 'none',
          width: '30%',
        },
      },
      {
        label: i18n.t('common.currency'),
        dataId: 'currency',
        styles: {
          textTransform: 'none',
          width: '13%',
          textAlign: 'center',
        },
      },
      {
        label: i18n.t('common.text.cost'),
        dataId: 'cost_unit',
        styles: {
          textTransform: 'none',
          width: '15%',
          textAlign: 'center',
        },
      },
      {
        label: i18n.t('common.text.quantity'),
        dataId: 'quantity',
        styles: {
          textTransform: 'none',
          width: '13%',
          textAlign: 'center',
        },
      },
      {
        label: i18n.t('common.text.total'),
        dataId: 'total_unit',
        styles: {
          textTransform: 'none',
          width: '18%',
          textAlign: 'center',
        },
      },
      {
        label: '',
        dataId: 'action',
        styles: {
          textTransform: 'none',
          width: '5%',
          textAlign: 'center',
        },
      },
    ];
  },
};

export const socialTypes = {
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  TIKTOK: 'TIKTOK',
  X: 'X',
};
export const socialPlatforms = {
  FACEBOOK: {
    key: 'FACEBOOK',
    displayName: 'Facebook',
    contentType: {
      POST: 'Facebook Post',
      REEL: 'Facebook Reel',
    },
  },
  INSTAGRAM: {
    key: 'INSTAGRAM',
    displayName: 'Instagram',
    contentType: {
      POST: 'Instagram Post',
      STORY: 'Instagram Story',
    },
  },
  TIKTOK: {
    key: 'TIKTOK',
    displayName: 'TikTok',
    contentType: {
      POST: 'TikTok Post',
    },
  },
  X: {
    key: 'X',
    displayName: 'X',
    contentType: {
      POST: 'X Post',
    },
  },
};

export const MAX_FILES_UPLOAD = 10;

export const MAX_SIZE_UPLOAD_PHOTO = 5242880; // 5MB
export const MAX_SIZE_UPLOAD_VIDEO = 26214400; // 25MB
export const MAX_SIZE_UPLOAD_WORK_MEDIA = 104857600; // 100MB
export const PLATFORM_FEE_RATE = 0.05;

/**
 * Fixed fee for commercial transactions
 * (https://www.paypal.com/us/webapps/mpp/merchant-fees#:~:text=Fixed%20fee%20for%20commercial%20transactions%20(based%20on%20currency%20received)
 */
export const fixedFee = {
  USD: 0.49,
  THB: 15.0,
};
/**
 * US – When using the Payouts API Fee - 0.25 USD
 * https://www.paypal.com/us/webapps/mpp/merchant-fees?_ga=1.202343249.1340029711.1692606670#domestic:~:text=US%20%E2%80%93%20When%20using%20the%0APayouts%20API%20Fee
 */
export const payoutFixedFee = {
  USD: 0.25, // 0.25 USD
  THB: 9.0,
};

export const PAYPAL_INVOICE_FEE = 0.0349; // equivalent 3.49% (Commercial Transactions Fee https://www.paypal.com/us/webapps/mpp/merchant-fees#:~:text=United%20States%20(US)-,Commercial%20Transaction%20Rates,-When%20you%20accept)
export const PLATFORM_WITHDRAW_FEE = 0.03; // equivalent 3% for Influencer

export const applicantsColumns: ColumnState[] = [
  {
    label: 'Social media',
    dataId: 'social_media',
    styles: {
      textTransform: 'none',
      width: '25%',
    },
  },
  {
    label: 'Cost/Unit',
    dataId: 'cost_unit',
    styles: {
      textTransform: 'none',
      width: '25%',
    },
  },
  {
    label: 'Quantity',
    dataId: 'quantity',
    styles: {
      textTransform: 'none',
      width: '25%',
    },
  },
  {
    label: 'Total/Unit',
    dataId: 'total_unit',
    styles: {
      textTransform: 'none',
      width: '25%',
    },
  },
];

export const IMAGE_EXTENSIONS = ['.jpg', '.jpeg', '.png'];
export const VIDEO_EXTENSIONS = [
  '.webm',
  '.mpg',
  '.mp2',
  '.mpeg',
  '.mpe',
  '.mpv',
  '.ogg',
  '.mp4',
  '.m4p',
  '.m4v',
  '.avi',
  '.wmv',
  '.mov',
  '.qt',
  '.flv',
  '.swf',
];

export const orderColumns: ColumnState[] = [
  {
    label: '',
    dataId: 'no',
    styles: {
      textTransform: 'none',
      width: '6%',
    },
  },
  {
    label: i18n.t('Campaign'),
    dataId: 'campaign',
    styles: {
      textTransform: 'none',
      width: '30%',
    },
  },
  {
    label: i18n.t('common.currency'),
    dataId: 'currency',
    styles: {
      textTransform: 'none',
      width: '13%',
      textAlign: 'center',
    },
  },
  {
    label: i18n.t('common.text.cost'),
    dataId: 'cost_unit',
    styles: {
      textTransform: 'none',
      width: '15%',
      textAlign: 'center',
    },
  },
  {
    label: i18n.t('common.text.quantity'),
    dataId: 'quantity',
    styles: {
      textTransform: 'none',
      width: '13%',
      textAlign: 'center',
    },
  },
  {
    label: i18n.t('common.text.total'),
    dataId: 'total_unit',
    styles: {
      textTransform: 'none',
      width: '18%',
      textAlign: 'center',
    },
  },
  {
    label: '',
    dataId: 'action',
    styles: {
      textTransform: 'none',
      width: '5%',
      textAlign: 'center',
    },
  },
];

export const brandOrderColumns: ColumnState[] = [
  {
    label: '',
    dataId: 'campaign',
    styles: {
      textTransform: 'none',
      width: '5%',
    },
  },
  {
    label: i18n.t('orders.table.des'),
    dataId: 'description',
    styles: {
      textTransform: 'none',
      width: '35%',
    },
  },
  {
    label: i18n.t('orders.table.billingGate'),
    dataId: 'date',
    styles: {
      textTransform: 'none',
      width: '19%',
      textAlign: 'center',
    },
  },
  {
    label: i18n.t('common.text.total'),
    dataId: 'total_unit',
    styles: {
      textTransform: 'none',
      width: '14%',
      textAlign: 'center',
    },
  },
  {
    label: i18n.t('orders.table.status'),
    dataId: 'status',
    styles: {
      textTransform: 'none',
      width: '12%',
      textAlign: 'center',
    },
  },
  {
    label: '',
    dataId: 'action',
    styles: {
      textTransform: 'none',
      width: '15%',
      textAlign: 'center',
    },
  },
];

export const brandOrderDetailColumns: ColumnState[] = [
  {
    label: 'Description',
    dataId: 'description',
    styles: {
      textTransform: 'none',
      width: '55%',
    },
  },
  {
    label: 'Unit',
    dataId: 'unit',
    styles: {
      textTransform: 'none',
      width: '15%',
      textAlign: 'center',
    },
  },
  {
    label: 'Price',
    dataId: 'price',
    styles: {
      textTransform: 'none',
      width: '15%',
      textAlign: 'center',
    },
  },
];

export const balanceChangeHistoryColumns: ColumnState[] = [
  {
    label: 'Date',
    dataId: 'date',
    styles: {
      textTransform: 'none',
      width: '40%',
    },
  },
  {
    label: 'Amount',
    dataId: 'amount',
    styles: {
      textTransform: 'none',
      width: '20%',
    },
  },
  {
    label: 'Type',
    dataId: 'type',
    styles: {
      textTransform: 'none',
      width: '20%',
    },
  },
  {
    label: 'Status',
    dataId: 'status',
    styles: {
      textTransform: 'none',
      width: '20%',
    },
  },
];

export const onGoingCampaignColumns: ColumnState[] = [
  {
    label: 'Campaign',
    dataId: 'campaign',
    styles: {
      textTransform: 'none',
      width: '70%',
    },
  },
  {
    label: 'Budget',
    dataId: 'budget',
    styles: {
      textTransform: 'none',
      width: '30%',
    },
  },
];

export const influencerCostColumns: ColumnState[] = [
  {
    label: 'Work type',
    dataId: 'description',
    styles: {
      textTransform: 'none',
      width: '55%',
    },
  },
  {
    label: 'Amount',
    dataId: 'unit',
    styles: {
      textTransform: 'none',
      width: '15%',
      textAlign: 'center',
    },
  },
  {
    label: 'Total Cost',
    dataId: 'price',
    styles: {
      textTransform: 'none',
      width: '15%',
      textAlign: 'center',
    },
  },
];

export const LANGUAGES_LIST = [
  {
    value: 'en',
    label: 'en',
    icon: ICONS.FLAG_EN,
  },
  {
    value: 'vi',
    label: 'vi',
    icon: ICONS.FLAG_VI,
  },
  {
    value: 'th',
    label: 'th',
    icon: ICONS.FLAG_THAI,
  },
];

export const CURRENCY_LIST = [
  { value: CurrencyType.USD, label: CurrencyType.USD },
  { value: CurrencyType.THB, label: CurrencyType.THB },
];

export const CAMPAIGN_STATUS_LIST = [
  { value: CAMPAIGN_STATUS_TYPE.COMING_SOON, label: CAMPAIGN_STATUS_TYPE.COMING_SOON },
  { value: CAMPAIGN_STATUS_TYPE.ON_GOING, label: CAMPAIGN_STATUS_TYPE.ON_GOING },
  { value: CAMPAIGN_STATUS_TYPE.PAST, label: CAMPAIGN_STATUS_TYPE.PAST },
];
