import { BalanceChangeRecordDTO, OrderPaypalInvoiceDTO } from '@services/types/apiType';
import { PagingConnection, PagingQueryParams, RequestBalanceChangeParams } from '@services/types/common';
import { AxiosInstance } from 'axios';

const balanceChange = 'balance-change';

export default class BalanceChangeService {
  constructor(private axios: AxiosInstance) {}

  getBalanceChangeHistory = async (params: PagingQueryParams): Promise<PagingConnection<BalanceChangeRecordDTO>> => {
    const { data } = await this.axios.get(`${balanceChange}`, { params });
    return data;
  };

  requestBalanceChange = async (params: RequestBalanceChangeParams): Promise<BalanceChangeRecordDTO> => {
    const { data } = await this.axios.post(`${balanceChange}`, params);
    return data;
  };

  getRequestDetails = async (id: string): Promise<{ invoice: OrderPaypalInvoiceDTO }> => {
    const { data } = await this.axios.get(`${balanceChange}/${id}`);
    return data;
  };

  cancelRequest = async (id: string): Promise<BalanceChangeRecordDTO> => {
    const { data } = await this.axios.put(`${balanceChange}/${id}`);
    return data;
  };
}
