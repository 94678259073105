import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: {
  USD_TO_THB: number;
  THB_TO_USD: number;
} = {
  USD_TO_THB: 36.25,
  THB_TO_USD: 0.028,
};
const exchangeSlice = createSlice({
  name: 'exchange',
  initialState,
  reducers: {
    setExchangeRate: (
      state,
      action: PayloadAction<{
        USD_TO_THB: number;
        THB_TO_USD: number;
      }>,
    ) => {
      state.THB_TO_USD = action.payload.THB_TO_USD;
      state.USD_TO_THB = action.payload.USD_TO_THB;
    },
  },
});

export const { setExchangeRate } = exchangeSlice.actions;
export default exchangeSlice.reducer;
