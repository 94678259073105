import Footer from '@components/Footer/Footer';
import NavBar from '@components/Navbar/NavBar';
import SignOnBonusBanner from '@components/SignOnBonusBanner/SignOnBonusBanner';
import React, { useEffect } from 'react';
import './DefaultLayout.scss';

export interface DefaultLayoutProps {
  children: React.ReactNode;
}

/**
 *
 * This layout included NavBar and Footer component
 */
const DefaultLayout: React.FunctionComponent<DefaultLayoutProps> = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="default-layout">
      <SignOnBonusBanner />
      <NavBar className="default-layout__navbar" />
      <div className="default-layout__content">{children}</div>
      <Footer />
    </div>
  );
};

export default DefaultLayout;
